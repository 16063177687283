import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import userContext from "../config/contextUser";

import { ReactComponent as NearshoreLogo } from '../images/logo.svg'
import "./Sidebar.scss";

const Sidebar = () => {
    const routes = useContext(userContext).routes;
    //example on how to take user role
    const onRoute = useLocation();

    return (
        <aside className="sidebar-container">
            <div className="sidebar-logo">
                <Link to="/">
                    <NearshoreLogo />
                </Link>
            </div>
            <div className="sidebar-menu">
                {
                    <ul className='sidebar-list'>
                        {routes.map(item => {
                            return (
                                <li key={item.path} className={onRoute.pathname === item.path ? 'active' : ''}>
                                    <Link to={item.path}>
                                        <div className="icon">{item.icon}</div>
                                        <span>{item.title}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                }
            </div>
        </aside>
    );
};

export default Sidebar;