
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_ENDPOINT } from "../../../../API/constants";

export const rewardCategoryApi = createApi({
  reducerPath: 'rewardCategory',

  baseQuery: fetchBaseQuery({
    baseUrl: URL_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.accessToken;
      if (token) {
        headers.set('Authorization', token);
      }
    }
  }),

  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => '/categories',
      providesTags: (result) =>
        result ?
          [
            ...result.map(({ id }) => ({
              type: 'Category', id
            })),
            { type: 'Category', id: 'LIST' }
          ] : [
            { type: 'Category', id: 'LIST' }
          ]
    }),
    getCategoryMeasures: builder.query({
      query: () => '/categories/measures',
    }),
    updateCategory: builder.mutation({
      query: ({ categoryId, body }) => ({
        url: `/categories/${categoryId}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: (result, error, { categoryId }) =>
        [{ type: 'Category', categoryId }],
    }),
    createCategory: builder.mutation({
      query: ({ body }) => ({
        url: '/categories',
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'Category', id: 'LIST' }],
    })
  }),
});

export const {
  useGetCategoryMeasuresQuery,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
  useCreateCategoryMutation
} = rewardCategoryApi;
