import React from "react";
import "./Input.scss";

const Input = ({ name, type = "text", placeholder = "Name", disabled, value, onChange, required, ...props }) => {

    return (
        <input
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            required={required}
            className="input"
            disabled={disabled}
            {...props}
        />
    );
};

export default Input;