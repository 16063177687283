
import React, { useContext } from 'react';
import Title from '../../components/Title';
import userContext from "../../config/contextUser";
import { Logout } from '../../components/Logout';
// import Button from '../../components/Button';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSync } from "@fortawesome/free-solid-svg-icons";

const Employee = () => {
    const user = useContext(userContext).user;
    // const [loading, setLoading] = useState(false);

    // const handleSync = async () => {
    //     const promise = new Promise((resolve) => {
    //         setTimeout(() => {
    //             resolve("resolved")
    //         }, 2300)
    //     });
    //     setLoading(true);
    //     return promise.then(() => {
    //         setLoading(false)

    //     })
    // }

    return (
        <>
            <Title>
                <Logout user={user} />
            </Title>
            <div className='animate__animated animate__fadeIn'>
                <h1>
                    "Welcome to our Rewards System: Unlock Extraordinary Benefits!"
                </h1>
                <p>
                    <em>
                        Earn, Redeem, Repeat: Unleash the Power of Reward!
                    </em>
                </p>

                {/* <div>
                    <Button disable={loading} onClick={() => { console.log("Click"); handleSync() }}>
                        <FontAwesomeIcon icon={faSync} spinPulse={loading} />
                        Sync Focalpoint Data
                    </Button>
                </div> */}
            </div>
        </>

    )
}

export default Employee;