import { faTrophy, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

const ToastMessage = ({ message }) => {
  return (
    <div className="toast">
      <FontAwesomeIcon icon={faWarning} />
      {message}
    </div>
  )
}

const toastPoperties = {
  position: toast.POSITION.TOP_RIGHT,
  progress: 0,
  hideProgressBar: true,
  style: {
    color: '#4773b'
  }
}

const ToastSuccessfull = ({ message }) => {
  return (
    <div className="toast">
      <FontAwesomeIcon icon={faTrophy} />
      {message}
    </div>);
}

export const toastError = (message) => toast(
  <ToastMessage message={message} />, toastPoperties);

export const toastSuccess = (message) => toast(
  <ToastSuccessfull message={message} />, toastPoperties
);