import React from "react";
import "./Form.scss";

const Form = ({children, classes, onSubmit}) => {

    const handleSubmit = (event) => {
        event.preventDefault();
        //Additional actions like send data to server
        onSubmit();
      }

    return (
        <form className={`form ${classes}`} onSubmit={handleSubmit}>
            {children}
        </form>
    );
};

export default Form;