import { useState } from 'react'
import './Toggle.scss'

export const Toggle = ({ toggled, onClick }) => {
  const [isToggled, toggle] = useState(toggled)

  const handleClick = () => {
    toggle(!isToggled)
    onClick(!isToggled)
  }

  return (
    <label className='toggle-label'>
      <input
        type="checkbox"
        defaultChecked={isToggled}
        onClick={handleClick}
      />
      <span />
    </label>
  )
}