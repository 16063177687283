import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faCoins, faTrophy, faPersonChalkboard, faGift, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import { routesWithRoles } from '../services/configRoutes';
import { intersection } from '../utils/utils';

const MenuSiderbar = [
  {
    title: 'Points Earned by Employee',
    path: '/points-by-employee',
    icon: <FontAwesomeIcon icon={faCoins} />
  },
  {
    title: 'Area Points',
    path: '/coe-points',
    icon: <FontAwesomeIcon icon={faChartLine} />
  },
  {
    title: 'Area Set Up',
    path: '/coe-setup',
    icon: <FontAwesomeIcon icon={faPersonChalkboard} />
  },
  {
    title: 'Category Catalog',
    path: '/reward-category-catalog',
    icon: <FontAwesomeIcon icon={faTrophy} />
  },
  {
    title: 'Redeem Points',
    path: '/redeem-points',
    icon: <FontAwesomeIcon icon={faGift} />
  },
  {
    title: 'Employee Points',
    path: '/employees-points',
    icon: <FontAwesomeIcon icon={faUserGraduate} />
  }
];

export const getMenuSidebarByUserRoles = async (userRoles) => {
  const routes = await routesWithRoles();
  const permissions = routes.map(
    (route) => ({
      ...route,
      access: intersection(route.roles, userRoles)
    }))
    .filter((route) => route.access.length > 0);

  return MenuSiderbar.map(
    (menu) => ({
      ...menu,
      ...permissions[
      permissions
        .map((permission) => permission.path)
        .indexOf(menu.path)
      ]
    }))
    .filter((menu) => menu.access !== undefined);
}
