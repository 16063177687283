import React from "react";
import './Title.scss';

export default function Title ({ children, isCentered }) {
  
  return (
    <div className={`title ${isCentered ? "title--centered" : ""}`}>
      {children}
    </div>
  );
};