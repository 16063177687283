import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react"; import Input from "../../../components/Input"
import Label from "../../../components/Label"
import { useForm } from "../../../hooks/useForm";
import Autocomplete from "../../../components/Autocomplete";
import { employeeFullName, formatAllEmployees, orderBy } from "../../../utils/utils";
import Form from "../../../components/Form";
import Button from "../../../components/Button";
import '../css/CoeSetup.scss';
import { useCreateAreaSetUpMutation, useGetAreaQuery, useUpdateAreaSetUpMutation } from "../../../store/slices/areaSetup";
import Loader from "../../../components/Loader";
import { toastError, toastSuccess } from "../../../utils/alerts";
import { getAreaResponsible } from "../../../store/slices/areaResponsible";
import userContext from "../../../config/contextUser";

export const AreaSetup = ({ areaId, type, onCloseModal }) => {
  const {
    onResetForm: onResetAreaForm,
    formState: areaForm,
    onSetForm,
    onInputChange: handleInputChange
  } = useForm({
    areaName: "",
    responsibles: []
  });

  const dispatch = useDispatch();
  const user = useContext(userContext).user;

  const { employees: employeesList, isLoadingEmployees } = useSelector(state => state.focalPoint);
  const { isLoading: isLoadingAreaDetail, data: areaDetail } = useGetAreaQuery(areaId, { skip: areaId === 0 });
  const [createAreaSetUp, { isLoading: isCreating }] = useCreateAreaSetUpMutation();
  const [updateAreaSetUp, { isLoading: isUpdating }] = useUpdateAreaSetUpMutation();


  const [errorValidations, setErrorValidations] = useState({
    areaName: '',
    responsibles: '',
    year: ''
  });

  const [employeeList, setEmployeeList] = useState([]);

  const mapNewResponsibles = (responsibles) => {
    return responsibles.map(
      (responsible) => employeesList
        .find((employee) => employee.id === (responsible.id || responsible.employeeId)))
      .map((responsible) => (
        {
          ssoEmployeeId: responsible.id,
          employeeName: employeeFullName(responsible),
          email: responsible.email,
          managerId: responsible.manager_id
        })
      );
  }

  const mapUpdateResponsibles = (responsibles) => {
    return responsibles.map((responsible) => {
      const employeeInfo = employeesList.find(
        (employee) => employee.id === (responsible.id || responsible.ssoEmployeeId))
      if (!responsible.hasOwnProperty("employeeIdKey")) {
        return {
          ...responsible,
          ssoEmployeeId: responsible.id,
          email: employeeInfo.email,
          managerId: employeeInfo.manager_id
        }
      }
      else {
        return {
          ...responsible,
          email: employeeInfo.email,
          managerId: employeeInfo.manager_id
        }
      }
    });
  }
  const isValidName = () => {
    if (!/^[a-zA-Z0-9&$() -]+$/.test(areaForm.areaName) && areaForm.areaName.length < 80) {
      setErrorValidations((errorState) => ({
        ...errorState,
        areaName: 'Area Name should not contains special characters'
      }));
      return false;
    } else {
      setErrorValidations((errorState) => ({
        ...errorState,
        areaName: ''
      }));
      return true;
    }
  }

  const addNewCategory = async () => {
    if (areaForm.responsibles.length === 0) {
      setErrorValidations((errorState) => ({
        ...errorState,
        responsibles: 'Select at least one responsible'
      }));
      toastError("Please fill out the required fields");
      return;
    }

    if (!isValidName()) {
      toastError("Please fill out the required fields");
      return;
    }

    const { responsibles } = areaForm;
    const formatResponsibles = mapNewResponsibles(responsibles);

    try {
      areaForm.responsibles = formatResponsibles;
      await createAreaSetUp({ body: areaForm });
      toastSuccess("Area successfully saved");
    } catch (error) {
      toastError(`Error saving Area`);
      throw new Error(error);
    } finally {
      onCloseModal();
    }
  }

  const updateArea = async () => {
    if (areaForm.responsibles.length === 0) {
      setErrorValidations((errorState) => ({
        ...errorState,
        responsibles: 'Select at least one responsible'
      }));
      toastError("Please fill out the required fields");
      return;
    }

    if (!isValidName()) {
      toastError("Please fill out the required fields");
      return;
    }

    const { responsibles } = areaForm;
    const mapResponsibles = mapUpdateResponsibles(responsibles);
    areaForm.responsibles = mapResponsibles;

    const body = {
      ...areaForm,
      areaId
    }

    try {
      await updateAreaSetUp({ areaId, body });
      dispatch(
        getAreaResponsible(user.employeeId)
      )
    }
    catch (error) {
      toastError(`Error Update Area`);
      throw new Error(error);
    } finally {
      onCloseModal();
    }
  }

  useEffect(() => {

    if (areaDetail) {
      const { responsibles, areaName } = areaDetail;
      onSetForm({ ...areaForm, areaName, responsibles });
    }
    // eslint-disable-next-line
  }, [areaDetail]);

  useEffect(() => {
    if (employeesList) {
      const filteredeEmployees = formatAllEmployees(employeesList);
      setEmployeeList(filteredeEmployees);
    }
    return () => {
      onResetAreaForm();
    }
    // eslint-disable-next-line
  }, [employeesList]);

  useEffect(() => {
    if (type === 'new') {
      onResetAreaForm();
    }
    // eslint-disable-next-line
  }, []);

  // Form Validations
  useEffect(() => {
    if (areaForm.responsibles.length > 0) {
      setErrorValidations((errorState) => ({
        ...errorState,
        responsibles: ''
      }));
      return;
    }
  }, [areaForm.responsibles]);

  useEffect(() => {
    if (areaForm.areaName.length === 0) {
      setErrorValidations((errorState) => ({
        ...errorState,
        areaName: ''
      }));
      return;
    }
    isValidName();
    // eslint-disable-next-line
  }, [areaForm.areaName]);

  return ((
    isLoadingEmployees ||
    isLoadingAreaDetail ||
    isCreating ||
    isUpdating) ? <Loader /> :
    <section className="coe-setup__form">
      <Form classes="center" onSubmit={type === 'new' ? () => addNewCategory() : () => updateArea()}>
        <Label type="grid">
          Name
          <Input
            type="text"
            placeholder="Name"
            name="areaName"
            value={areaForm.areaName}
            required
            disabled={isCreating || isUpdating}
            autoComplete="off"
            onChange={(e) => handleInputChange(e)}
          />
        </Label>
        <div className="message">
          <small className="warning">
            {errorValidations.areaName}
          </small>
        </div>
        <Label type="grid">
          Responsibles
          <Autocomplete onChange={
            (e, val) => {
              onSetForm({ ...areaForm, responsibles: val })
            }}
            options={orderBy(employeeList, 'employeeName')}
            name="responsibles"
            value={areaForm.responsibles}
            disabled={isCreating || isUpdating}
            getOptionLabel={(option) => option.employeeName}
            isOptionEqualToValue={(option, value) => option.employeeName === value.employeeName}
            required
            label="Employees" placeholder="Add" />
        </Label>
        <div className="message">
          <small className="warning">
            {errorValidations.responsibles}
          </small>
        </div>

        <div className="form__actions">
          <Button type="button" disabled={isCreating || isUpdating} secondary onClick={() => onCloseModal()}>Cancel</Button>
          <Button disabled={isCreating || isUpdating} type="submit">Save</Button>
        </div>
      </Form>
    </section>
  )
}
