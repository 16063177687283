import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StorageItems } from "../../../utils/constants";
import { diffTime } from "../../../utils/utils";
import { startLogout } from "../../../store/slices/auth";
import SessionTimeoutDialog from "../../../components/SetTimeOutDialog";
import awsExports from "../../../config/aws-exports";
import useAuth from "../../../hooks/useAuth";

const events = ['click', 'scroll', 'load', 'keydown'];
export const SessionTimeOut = () => {
  const { isAuthenticated, isAccessTokenRequest } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [onShowModal, setShowModal] = useState(false);

  const { handleRefreshToken } = useAuth({
    provider: awsExports.PROVIDER,
    options: {
      userPoolId: awsExports.USER_POOL_ID,
      userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
      oauth: {
        domain: awsExports.OAUTH_DOMAIN,
        scope: awsExports.OAUTH_SCOPE,
        redirectSignIn: awsExports.OAUTH_REDIRECT_SIGN_IN,
        redirectSignOut: awsExports.OAUTH_REDIRECT_SIGN_OUT,
        region: awsExports.REGION,
        responseType: awsExports.OAUTH_RESPONSE_TYPE
      },
      federatedSignIn: {
        customProvider: awsExports.PROVIDER
      }
    }
  });

  const eventHandler = (event) => {
    const initialTime = localStorage.getItem(StorageItems.TIME_INTERACTION);
    const currentTime = new Date().getTime();
    const diff = diffTime(+initialTime, currentTime);

    if (initialTime === null) {
      return;
    }

    if (diff >= 58) {
      setShowModal(true);
    }
  }

  useEffect(() => {
    if (!isAuthenticated) {
      setShowModal(false);
    }
    // eslint-disable-next-line 
  }, [onShowModal]);

  useEffect(() => {
    if (!isAccessTokenRequest) {
      setShowModal(false);
    }
  }, [isAccessTokenRequest])


  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler)
    });
    setShowModal(false);
  }

  const removeEvents = () => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, eventHandler)
    });
    setShowModal(false)
  };

  useEffect(() => {
    addEvents();

    return (() => {
      removeEvents();
    })
    // eslint-disable-next-line
  }, []);

  const handleContinueSession = async () => {
    await handleRefreshToken();
  }

  return (
    <>
      <SessionTimeoutDialog
        isLoading={isAccessTokenRequest}
        onLogout={() => dispatch(startLogout())}
        onContinue={handleContinueSession}
        open={onShowModal}
      />
    </>
  );
}
