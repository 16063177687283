import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import './Modal.scss';

const Modal = ({ open, onClose, children }) => {
  if (!open) return null;

  return ReactDOM.createPortal (
    <section className="modal">
      <div className="modal__box">
        <div className="modal__close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes}/>
        </div>
        <div className="modal__scroll">
          {children}
        </div>
      </div>
    </section>, document.body
  );
};

export default Modal;