import axiosInstance from "../../../API/axiosInstance";
import { URL_ENDPOINT } from "../../../API/constants";
import { setAreaResponsible, startLoadingResponsible } from "./areaResponsibleSlice";

export const getAreaResponsible = (userId) => {
  return async (dispatch, getState) => {
    dispatch(startLoadingResponsible());

    const { accessToken } = getState().auth;

    const { data } = await axiosInstance({
      baseURL: URL_ENDPOINT,
      url: `/areas/responsibles/${userId}`,
      headers: {
        Authorization: accessToken
      }
    });

    if (data.length > 0) {
      dispatch(setAreaResponsible({
        areaId: data[0].areaId,
        areaName: data[0].areaName
      }));
      return;
    }

    dispatch(setAreaResponsible({
      areaId: "",
      areaName: ""
    }));
  }
}
