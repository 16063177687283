import { useState } from 'react';

export const useForm = (initialForm = {}) => {
  const [formState, setFormState] = useState(initialForm);

  const onInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({
      ...formState,
      [name]: value
    });
  }

  const onResetForm = () => {
    setFormState(initialForm);
  }

  const onSetForm = (form) => setFormState(form)

  return {
    ...formState,
    formState,
    onSetForm,
    onInputChange,
    onResetForm,
  }
}
