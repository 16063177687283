import { useEffect, useState } from "react";
import Label from "../../../components/Label";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { groupBy } from "../../../utils/utils";
import { useGetAreaQuery } from "../../../store/slices/areaSetup";

export const DetailsArea = ({ areaId }) => {
  const [responsibles, setResponsibles] = useState([]);
  const [years, setYears] = useState([]);
  const [collapse, setCollapse] = useState({
    year: '',
    isCollapse: true
  });
  const { isLoading: isLoadingAreaDetail, data: areaDetail } = useGetAreaQuery(areaId);

  useEffect(() => {
    if (areaDetail) {
      if (areaDetail.hasOwnProperty("quarters")) {
        const grouped = groupBy(areaDetail.quarters, 'year');
        setYears(grouped);
      }
      const responsiblesItems = areaDetail.responsibles.map(
        (responsible) => responsible.employeeName
      );
      setResponsibles(responsiblesItems)
    }
    // eslint-disable-next-line
  }, [areaDetail]);

  return (isLoadingAreaDetail ? <div>Loading details</div> :
    <div className="coe-setup__content">
      <div className="coe-setup__details">
        <div className="coe-setup__detailItem">
          <Label>
            Responsibles:
          </Label>
          <ol>
            {
              responsibles.map((responsible, index) => (
                <li key={`${responsible}-${index}`}>{`${responsible}`}</li>
              ))
            }
          </ol>
        </div>
      </div>
      <div className="coe-setup__detailItem">
        <section className="coe-setup">
          <div className="coe-setup__areas">
            {
              Object.keys(years).map((year) => (
                <div className="collapse-item" key={year}>
                  <div onClick={() => setCollapse({
                    ...collapse,
                    year,
                    isCollapse: !collapse.isCollapse
                  })}>
                    <strong>
                      {year}
                    </strong>
                    <FontAwesomeIcon style={{
                      fontWeight: "bold"
                    }} size="2xs"
                      icon={
                        collapse.isCollapse === true &&
                          collapse.year === year ? faChevronDown :
                          faChevronRight
                      } />
                  </div>
                  {
                    collapse.isCollapse === true &&
                    collapse.year === year &&
                    <table className={
                      `coe-points__table ${collapse.isCollapse === true &&
                        collapse.year === year ? 'active' : ''}`
                    }>
                      <thead>
                        <tr>
                          <th>Quarter</th>
                          <th>Points</th>
                          <th>Year</th>
                        </tr>
                      </thead>
                      <tbody>
                        {years[year].sort(
                          (a, b) => a.quarter - b.quarter
                        ).map((quarter, index) => (
                          <tr key={`index-${index}-${quarter.quarter}`} className={index % 2 === 0 ? 'odd-row' : ''}>
                            <td>{quarter.quarter}</td>
                            <td>{quarter.points}</td>
                            <td>{quarter.year}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  }

                </div>
              ))
            }
          </div>
        </section>
      </div>
    </div>
  )
}
