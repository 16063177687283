import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Button.scss';

const Button = (props) => {

  return (
    <button
      disabled={props.disabled}
      type={props.type}
      id={props.id}
      onClick={props.onClick}
      className={
        `${props.secondary === true ? "secondary" : ""} ${props.disabled ? 'disable-btn' : ''}`}>
      {props.icon && <FontAwesomeIcon icon={props.icon} />}
      {" "}
      {props.children}
    </button>
  );
};

export default Button;