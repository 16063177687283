// import axiosInstance from "../API/axiosInstance";
import data from "../temp/mockData.json";

export const routesWithRoles = async() => {
  // reward/routes
  try {
    // const response = await axiosInstance.get('/reward/roleroutes');
    return data.RoleRoutes;
  } catch (error) {
    throw new Error(`Error get '/reward/roleroutes'`);
  }
}