import { useEffect, useMemo, useContext } from 'react'
import { Auth } from '@aws-amplify/auth'
import contextUser from '../config/contextUser'
import { StorageItems } from '../utils/constants';
import { useLocalStorage } from './useLocalStorage';
import { useDispatch } from 'react-redux';
import { endAccessToken, setAccessToken, startAccessToken } from '../store/slices/auth';
import { getAllEmployees } from '../store/slices/focalPoint';
import { getAreaResponsible } from '../store/slices/areaResponsible';
import { toastError } from '../utils/alerts';

export default function useAuth({ provider, options }) {
  const dispatch = useDispatch();
  const { setValue } = useLocalStorage(StorageItems.ACCESS_TOKEN, '');
  const { setValue: setInteractionTime } = useLocalStorage(StorageItems.TIME_INTERACTION, '');

  const lastInteraction = localStorage.getItem(StorageItems.TIME_INTERACTION) || null;

  const setUserInfo = useContext(contextUser).setUser;
  const setGlobalLoading = useContext(contextUser).setLoading;

  const authCognito = useMemo(() => {
    Auth.configure(options)
    return Auth
  }, [options])

  useEffect(() => {
    authCognito.currentAuthenticatedUser().then((user) => {
      setGlobalLoading(true);
      const userData = {
        name: user.signInUserSession.idToken.payload.firstName,
        lastName: user.signInUserSession.idToken.payload.lastName,
        employeeId: user.signInUserSession.idToken.payload.employeeId,
        managerId: user.signInUserSession.idToken.payload.managerId,
        token: user.signInUserSession.accessToken.jwtToken,
        roles: user.signInUserSession.idToken.payload['cognito:groups']
      }
      const appAccessToken = user.signInUserSession.idToken.jwtToken;

      if (!lastInteraction) {
        setInteractionTime(new Date().getTime());
      }

      dispatch(
        setAccessToken(appAccessToken)
      );
      dispatch(
        getAllEmployees()
      );
      dispatch(
        getAreaResponsible(
          userData.employeeId
        )
      );
      setValue(appAccessToken);
      setUserInfo(userData);
      setGlobalLoading(false);
      // TODO: Avoid re asigned time after signIn
    }).catch((e) => {
      console.log(e);
      setGlobalLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCognito, setGlobalLoading, setUserInfo])

  const signIn = async () => {
    try {
      await authCognito.federatedSignIn({ provider });
    } catch (error) {
      throw new Error(error)
    }
  };

  const handleRefreshToken = async () => {
    try {
      const cognitoUser = await authCognito.currentAuthenticatedUser();
      const currentSession = await authCognito.currentSession();
      dispatch(startAccessToken());

      return cognitoUser.refreshSession(
        currentSession.refreshToken, async (err, session) => {
          if (err !== null) {
            toastError("Error trying refresh token, please retry");
            dispatch(endAccessToken("Error trying refresh token"));
            throw new Error("Error trying refresh token");
          }

          const { jwtToken } = await session.idToken;
          dispatch(
            setAccessToken(jwtToken)
          );
          setValue(jwtToken);
          setInteractionTime(new Date().getTime());
          return jwtToken;
        });

    } catch (error) {
      toastError("Error trying refresh token, please logout");
      throw new Error(error);
    }
  }

  return {
    signIn,
    handleRefreshToken
  }
}