import { memo } from "react";

export const TransitionQuarter = memo(({ savingQuarter }) => {
  return (
    <center>
      <div className="message">
        <small className="warning">
          {
            savingQuarter ?
              'Saving changes ...' : 'Do you want to save the changes?'
          }
        </small>
      </div>
    </center>
  )
})
