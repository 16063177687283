import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import './Logout.scss';
import { useDispatch } from 'react-redux';
import { startLogout } from '../store/slices/auth';

export const Logout = ({ user, title }) => {

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(startLogout());
  }

  return (
    <div className='logout-content'>
      {
        user ? <div>
          {user.name} {user.lastName} Home
        </div> : <>{title}</>
      }

      <Button onClick={() => handleLogout()}>
        <FontAwesomeIcon icon={faRightToBracket} />{' '}
        Logout
      </Button>
    </div>
  );
}
