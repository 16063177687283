import React, { useEffect, useMemo, useState } from "react";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Title from "../../components/Title";
import { faPencil, faPlusCircle, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import './css/CoeSetup.scss';
import { Logout } from "../../components/Logout";
import Loader from "../../components/Loader";
import { AreaQuartersSetup, AreaSetup, DetailsArea } from "./components";
import { useGetAreasQuery } from "../../store/slices/areaSetup";
import MaterialReactTable from "material-react-table";

const CoeSetUp = () => {
  const element = document.getElementById("card");
  const [openModal, setOpenModal] = useState(false);
  const [modalSettings, setModalSettings] = useState({
    text: "",
    type: ""
  });
  const [areaIdDetail, setAreaIdDetail] = useState(0);

  const { isLoading: isLoadingAreaList, data: areaList } = useGetAreasQuery();

  const handleClickOnCell = (cell) => {
    const { original: coe } = cell.row;
    onOpenModal("details", coe);
  }

  const areaNameColumn = {
    accessorKey: "areaName",
    header: "Area",
    muiTableBodyCellProps: ({ cell }) => ({
      onClick: () => handleClickOnCell(cell)
    }),
    size: 110, //large column
  }

  /* eslint-disable */
  const columns = useMemo(() => [areaNameColumn], []);
  /* eslint-enable */

  const onOpenModal = async (type, coeData) => {
    if (!coeData?.areaId || !coeData?.areaName) {
      setModalSettings({
        type: "new",
        text: "Add New Area"
      });
      setOpenModal(true);
      return;
    }

    const { areaId, areaName } = coeData;

    const typeTextMap = {
      edit: `Edit Area - ${areaName}`,
      quarter: `Add Quarter - ${areaName}`,
      details: `Area Details - ${areaName}`
    };

    let modalSettings = {
      type,
      text: typeTextMap[type]
    };

    setAreaIdDetail(areaId);
    setModalSettings(modalSettings);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  useEffect(() => {
    if (areaList) {
      if (areaList.length < 5) {
        element.classList.remove('card--main');
      }
    }
    // eslint-disable-next-line
  }, [areaList]);

  useEffect(() => {
    return () => {
      if (!element.classList.contains('card--main')) {
        element.classList.add('card--main');
      }
    }
    // eslint-disable-next-line
  }, []);

  return (isLoadingAreaList ? <Loader /> :
    <div className="animate__animated animate__fadeIn">
      <Title>
        <Logout title={'Area Set Up'} />
      </Title>
      <div>
        <Button block icon={faPlusCircle} onClick={() => { onOpenModal("new") }}>Add New Area</Button>
      </div>
      <div></div>
      <MaterialReactTable
        title="Areas"
        enableColumnResizing
        enableColumnActions={false}
        // enableColumnFilters={true}
        enableStickyHeader
        muiTableContainerProps={{
          sx: {
            height: {
              lg: '225px',
              xl: '550px'
            }
          }
        }}
        enableGlobalFilterModes
        initialState={{
          showGlobalFilter: true,
        }}

        positionGlobalFilter="left"
        muiSearchTextFieldProps={{
          placeholder: `  Search`,
          sx: {
            minWidth: '400px',
            fieldset: {
              border: 'none !important',
              outline: 'none !important'
            }
          }
        }}
        columns={columns}
        data={
          areaList || []
        }
        muiTableBodyCellProps={{
          sx: {
            fontSize: '16px',
            fontFamily: 'AvantGardeMedium',
            padding: '.5rem'
          },
        }}
        // muiTableBodyP\
        muiTableHeadCellProps={{
          sx: {
            fontWeight: 'bold',
            fontSize: '16px',
            fontFamily: 'AvantGardeMedium'
          },
        }}
        muiTableBodyProps={{
          sx: {
            '& tr:nth-of-type(odd)': {
              backgroundColor: '#f5f5f5'
            },
          },
        }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => ([
          <div className="coe-setup__actions">
            <Button icon={faLayerGroup} onClick={() => onOpenModal("quarter", row.original)}>Add quarter</Button>
            <Button icon={faPencil} onClick={() => onOpenModal("edit", row.original)}></Button>
          </div>
        ])}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: 'Actions',
            size: 50,
          },
        }}
      />

      <Modal open={openModal} onClose={() => handleCloseModal()}>
        <Title>{modalSettings.text}</Title>
        {
          modalSettings.type === 'details' ? (
            <DetailsArea areaId={areaIdDetail} />
          ) :
            <>
              {
                modalSettings.type === 'new' || modalSettings.type === 'edit' ?
                  <AreaSetup
                    areaId={areaIdDetail}
                    type={modalSettings.type}
                    onCloseModal={() => handleCloseModal()}
                  /> :
                  <AreaQuartersSetup
                    onCloseModal={() => handleCloseModal()}
                    areaId={areaIdDetail}
                  />
              }
            </>
        }
      </Modal>
    </div>
  )
};

export default CoeSetUp;