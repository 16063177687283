export const CHARSET = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!¡¿?#$%&=*';

// Local Staorage Items
export const StorageItems = {
  ACCESS_TOKEN: 'accessToken',
  TIME_INTERACTION: 'interactionTime',
}

export const OptionRoles = [
  { value: 'Admin', label: 'Admin' },
  { value: 'Manager', label: 'Manager' },
  { value: 'Rewarder', label: 'Rewarder' },
  { value: 'Employee', label: 'Employee' },
];

export const SSO_ROLES = {
  'admin-reward': 'Admin',
  'manager-reward': 'Manager',
  'rewarder-reward': 'Rewarder'
}

export const CategoryData = {
  "categoryId": "id",
  "measureId": "measure",
  "measure": "measureLabel",
  "description": "description",
  "automatic": "automatic",
  "points": "points",
  "maxPoints": "maxPoints"
}

export const optionsQuarter = [
  { value: '', label: '' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
];

export const headers = {
  'employeName': 'Employee Name',
  'earnedPoints': 'Earned Points',
  'redeemedPoints': 'Redeemed Points',
  'currentBalance': 'Current Balance'
}
