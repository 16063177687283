import React, { useState, useMemo, useEffect, useContext } from "react";
import MaterialReactTable from 'material-react-table';
import Title from "../../components/Title";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import "./css/PointsByEmployee.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Logout } from "../../components/Logout";
import userContext from "../../config/contextUser";
import { Toggle } from "../../components/Toggle";
import {
  availableQuarters,
  availableYears,
  formatDate,
  getCurrentQuarter,
  getQuartersByYear,
  initialFilters
} from "../../utils/utils";
import Loader from "../../components/Loader";
import { DetailsPointsEmpoyee, ExcelExport, Filters } from "./components";
import { 
  useGetPointsByManagerQuery,
  useGetPointsConversionFactorQuery
 } from "../../store/slices/pointsByEmployee/apis/pointsByEmployeeApi";
import { useGetWeekEndsQuery } from "../../store/slices/areaPoints/apis/areaPointsApi";
import { useForm } from "../../hooks/useForm";

const PointsByEmployee = () => {
  const user = useContext(userContext).user;
  const [openModal, setOpenModal] = useState(false);
  const [employeeData, setEmployeeData] = useState();
  const [filters, setFilters] = useState(true);
  const [searchBar, setSearchBar] = useState(false);

  const [quartersByYear, setQuartersByYear] = useState([]);
  const [weekEndings, setWeekEndings] = useState([]);
  const [initialFilter, setInitialFilter] = useState({});
  const [years, setYears] = useState([]);
  const [quarters, setQuarters] = useState([]);

  const {
    selectedQuarter,
    selectedYear,
    selectedWeekEnding,
    formState,
    onInputChange: handleSelect,
    onSetForm
  } = useForm({
    selectedQuarter: () => getCurrentQuarter(),
    selectedYear: new Date().getFullYear(),
    selectedWeekEnding: ''
  });

  //To prevent unnecessary calls
  const [isSelectedWeekend, setIsSelectedWeekend] = useState(false);

  const { isLoading, data: pointsEarned } = useGetPointsByManagerQuery({
    managerId: user.employeeId,
    ...initialFilter
  }, {
    skip: initialFilter?.from === undefined
  });

  const { isLoading: isLoadingWeekendings, data: weekends } = useGetWeekEndsQuery(
    { ...initialFilter },
    {
      skip: initialFilter?.from === undefined || isSelectedWeekend
    }
  );

  const { isLoading: isLoadingConversion, 
    data: pointsConversionFactor 
  } = useGetPointsConversionFactorQuery();

  const columns = useMemo(() => [
    {
      accessorKey: "employeName",
      header: "Employee",
      size: 110, //large column
    },
    {
      accessorKey: 'earnedPoints',
      header: "Earned",
      size: 70, //medium column
    },
    {
      //accessorFn function that combines multiple data together
      accessorKey: 'redeemedPoints',
      header: "Redeemed",
      size: 80, //medium column
    },
    {
      //accessorFn used to access nested data, though you could just use dot notation in an accessorKey
      accessorKey: 'currentBalance',
      header: 'Current Balance',
      size: 90, //medium column
    },
  ], []);

  const getAvailableYears = () => {
    const currentYears = availableYears().map((year) => ({
      value: year, label: year
    }));
    currentYears.unshift({ value: '', label: '' });
    setYears(currentYears);
  }

  const getAvailableQuarters = () => {
    const currentQuarters = availableQuarters().map((item) => ({
      value: item, label: item
    }));
    currentQuarters.unshift({ value: '', label: '' })
    setQuarters(currentQuarters);
  }

  const handleWeekendsFilters = async (selectedQuarter) => {
    const findQuarterFilter = quartersByYear.find(
      (quarter) => quarter.quarter === +selectedQuarter
    );
    setInitialFilter(findQuarterFilter);
  }

  const handleInitialFilter = async () => {
    const filters = initialFilters(selectedYear);
    setInitialFilter(filters);
    setWeekEndings([]);
  }

  // Filter Handlers

  useEffect(() => {
    setIsSelectedWeekend(false);

    if (!selectedQuarter && selectedYear) {
      handleInitialFilter();
      return;
    }

    if (selectedQuarter) {
      handleWeekendsFilters(selectedQuarter);
      return;
    }
    setWeekEndings([]);
    // eslint-disable-next-line
  }, [selectedQuarter]);

  useEffect(() => {
    if (selectedYear) {
      setIsSelectedWeekend(false);

      const currentYear = new Date().getFullYear();
      const filterYears = initialFilters(selectedYear);
      setInitialFilter(filterYears);
      const quarters = getQuartersByYear(selectedYear);
      setQuartersByYear(quarters);

      if (selectedYear !== currentYear) {
        return;
      }

      onSetForm({
        ...formState,
        selectedQuarter: getCurrentQuarter(),
        selectedWeekEnding: ''
      });
    } else {
      onSetForm({
        selectedQuarter: '',
        selectedYear: '',
        selectedWeekEnding: ''
      });
      setInitialFilter({
        from: '',
        to: ''
      });
    }
    // eslint-disable-next-line
  }, [selectedYear]);

  useEffect(() => {
    getAvailableYears();
    getAvailableQuarters();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedWeekEnding) {
      if (selectedQuarter) {
        handleWeekendsFilters(selectedQuarter)
      }
      return;
    }

    if (selectedWeekEnding) {
      setIsSelectedWeekend(true);
      setInitialFilter((state) => (
        {
          ...state,
          to: selectedWeekEnding
        }));
    }
    // eslint-disable-next-line
  }, [selectedWeekEnding]);

  useEffect(() => {
    if (weekends) {
      const optionsWeekEnds = weekends['week-endings'].map((weekEnd) => ({
        value: formatDate(weekEnd),
        label: formatDate(weekEnd)
      }))
      optionsWeekEnds.unshift({ value: '', label: '' });
      setWeekEndings(optionsWeekEnds);
    }
  }, [weekends]);

  return ((isLoading || isLoadingWeekendings || isLoadingConversion) ? <Loader /> :
    <>
      <div className="animate__animated animate__fadeIn">
        <Title>
          <Logout title={'Points Earned By Employee'} />
        </Title>
        <section className="points-by-employee">
          <div className="points-by-employee__info">
            <div>
              <strong>Manager:</strong>
              <span>{`${user.name} ${user.lastName}`}</span>
            </div>
          </div>
        </section>
        <div className="content-controls">
          <div className="toggle-control">
            <strong>Filters</strong>
            <Toggle
              onClick={() => setFilters(!filters)}
              toggled={filters} />
          </div>
          <div className="toggle-control">
            <strong>Search bar</strong>
            <Toggle
              onClick={() => setSearchBar(!searchBar)}
              toggled={searchBar} />
          </div>
          {
            pointsConversionFactor && pointsEarned?.length > 0 && (
              <div className="toggle-control">
                <strong>Export as Excel File</strong>
                <div className="export-action">
                  <ExcelExport
                    data={pointsEarned}
                    filters={initialFilter}
                    conversionFactor={pointsConversionFactor.pointsConversionFactor}
                  />
                </div>
              </div>
            )
          }
        </div>

        {filters && (
          <Filters
            selectedQuarter={selectedQuarter}
            selectedYear={selectedYear}
            selectedWeekEnding={selectedWeekEnding}
            years={years}
            quarters={quarters}
            weekEndings={weekEndings}
            initialFilter={initialFilter}
            handleSelect={handleSelect}
          />
        )}
      </div>

      {/* <ScrollerContainer> */}
      <MaterialReactTable
        title="Employees"
        enableColumnResizing
        enableColumnActions={false}
        // enableColumnFilters={true}
        enableStickyHeader
        muiTableContainerProps={{
          sx: {
            height: {
              lg: '225px',
              xl: '550px'
            }
          }
        }}
        enableGlobalFilterModes
        initialState={{
          showGlobalFilter: true,
        }}
        positionGlobalFilter="left"
        muiSearchTextFieldProps={{
          placeholder: `  Search`,
          sx: {
            minWidth: '400px',
            fieldset: {
              border: 'none !important',
              outline: 'none !important'
            }
          }
        }}
        // enablePagination={false}
        // enableSorting={false}
        // enableBottomToolbar={false}
        enableTopToolbar={searchBar}
        columns={columns}
        data={
          !initialFilter?.from && !initialFilter?.to ? [] : pointsEarned
        }
        muiTableBodyCellProps={{
          sx: {
            fontSize: '16px',
            fontFamily: 'AvantGardeMedium',
            padding: '.5rem'
          },
        }}
        // muiTableBodyP\
        muiTableHeadCellProps={{
          sx: {
            fontWeight: 'bold',
            fontSize: '16px',
            fontFamily: 'AvantGardeMedium'
          },
        }}
        muiTableBodyProps={{
          sx: {
            '& tr:nth-of-type(odd)': {
              backgroundColor: '#f5f5f5'
            },
          },
        }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <Button id="action-edit" onClick={() => {
            setOpenModal(true);
            setEmployeeData(row.original);
            // handleModal('edit', row.original)
          }}>
            <FontAwesomeIcon icon={faEye} />
          </Button>
        )}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: 'Details',
            size: 60,
          },
        }}
      />
      {/* </ScrollerContainer> */}

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div>
          {employeeData &&
            <>
              <Title>{employeeData.employeName}</Title>
              <DetailsPointsEmpoyee
                employeId={employeeData.employeeIdKey}
                filters={initialFilter}
              />
            </>
          }
        </div>
      </Modal>
    </>
  )
};

export default PointsByEmployee;
