import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography, } from '@mui/material';
import Button from './Button'
import { useSelector } from 'react-redux';

let countdownInterval;
let timeout;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SessionTimeoutDialog = ({ open, onLogout, onContinue, isLoading }) => {
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const { isAuthenticated } = useSelector(state => state.auth);

  const onIdle = () => {
    const delay = 1000 * 1;
    if (isAuthenticated) {
      timeout = setTimeout(() => {
        let countDown = 10;
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            onLogout()
          }
        }, 1000);
      }, delay);
    }
  };

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  useEffect(() => {
    if (open) {
      onIdle();
    }
    // eslint-disable-next-line 
  }, [open]);

  const handleContinue = () => {
    clearSessionInterval();
    clearSessionTimeout();
    onContinue();
  }

  return (
    <Dialog
      open={open}
      classes={{
        paper: {
          borderRadius: 0

        }
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        Session Timeout
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          The current session is about to expire in{" "}
          <span >{timeoutCountdown}</span> seconds.
        </Typography>
        <Typography variant="body2">{`Would you like to continue the session?`}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onLogout}
          disabled={isLoading}
          variant="contained"
        >
          Logout
        </Button>
        <Button
          onClick={handleContinue}
          disabled={isLoading}
          color="primary"
          variant="contained"
        >
          Continue Session
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SessionTimeoutDialog;
