import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { formatDateYear } from "../../../../utils/utils";
import { URL_ENDPOINT } from "../../../../API/constants";


export const areaPointsApi = createApi({
  reducerPath: 'areaPoints',
  tagTypes: ['AreasSetup'],
  baseQuery: fetchBaseQuery({
    baseUrl: URL_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.accessToken;
      if (token) {
        headers.set('Authorization', token);
      }
    }
  }),
  endpoints: (builder) => ({
    // Areas Points Endpoints
    getWeekEnds: builder.query({
      query:
        ({ from, to }) =>
          `/calendar/week-endings?startdate=${formatDateYear(from)}&enddate=${formatDateYear(to)}`,
    }),
    getRewardCategoriesList: builder.query({
      query:
        ({ areaId, from, to }) =>
          `/areas/${areaId}/points?startdate=${formatDateYear(from)}&enddate=${formatDateYear(to)}`,
      providesTags: (result) =>
        result.areaPoints ? [
          ...result.areaPoints.map(({ id }) => ({
            type: 'AreaPoint', id
          })),
          { type: 'AreaPoint', id: 'LIST' },
          { type: 'AreasSetup', id: 'UPDATED' }
        ] : [
          { type: 'AreasSetup', id: 'UPDATED' },
          { type: 'AreaPoint', id: 'LIST' },
        ]
    }),
    createAreaPoints: builder.mutation({
      query: ({ areaId, body }) => ({
        url: `/areas/${areaId}/points`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'AreaPoint', id: 'LIST' }]
    }),
    updateAreaPoint: builder.mutation({
      query: ({ areaId, body }) => ({
        url: `/points/${areaId}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: (result, error) =>
        [
          { type: 'AreaPoint', id: 'LIST' }
        ]
    }),
  })
});

export const {
  useGetRewardCategoriesListQuery,
  useGetWeekEndsQuery,
  useCreateAreaPointsMutation,
  useUpdateAreaPointMutation
} = areaPointsApi;
