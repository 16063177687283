import React, { useMemo, useState, useEffect, useContext } from "react";
import MaterialReactTable from 'material-react-table';
import Modal from "../../components/Modal";
import Label from "../../components/Label";
import Title from "../../components/Title";
import Form from "../../components/Form";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Button from "../../components/Button";
import { faPencil, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import './css/CoePoints.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "../../hooks/useForm";
import userContext from "../../config/contextUser";
import { Logout } from "../../components/Logout";
import { Toggle } from "../../components/Toggle";
import Loader from "../../components/Loader";
import { InputSelect } from "../../components/InputSelect";
import { InputDate } from "../../components/InputDate";
import { toastError } from "../../utils/alerts";
import { useSelector } from "react-redux";
import { useGetCategoriesQuery } from "../../store/slices/rewardCategory/apis/rewardCategoryApi";
import { useGetAreaQuery } from "../../store/slices/areaSetup/apis/areaSetupApi";
import {
  useCreateAreaPointsMutation,
  useGetRewardCategoriesListQuery,
  useGetWeekEndsQuery,
  useUpdateAreaPointMutation
} from "../../store/slices/areaPoints/apis/areaPointsApi";
import {
  availableQuarters,
  availableYears,
  employeeFullName,
  formatAllEmployees,
  formatDate,
  formatDateYear,
  getCurrentQuarter,
  getQuartersByYear,
  initialFilters
} from "../../utils/utils";
import { useGetRewardEmployeesQuery } from "../../store/slices/redeemPoints";
import { Filters } from "./components";

const CoE = () => {
  const user = useContext(userContext).user;
  const [initialFilter, setInitialFilter] = useState({});
  const [quartersByYear, setQuartersByYear] = useState([]);
  const [enableTable, setEnableTable] = useState(false);

  const [filters, setFilters] = useState(true);
  const [searchBar, setSearchBar] = useState(false);
  const [optionUsernames, setOptionUserNames] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [years, setYears] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const [weekEndings, setWeekEndings] = useState([]);
  const [modalSettings, setModalSettings] = useState({
    title: "",
    type: ""
  });
  const [pointsByCategory, setPointsByCategory] = useState(false);

  const {
    selectedQuarter,
    selectedYear,
    selectedWeekEnding,
    formState,
    onInputChange: handleSelect,
    onSetForm: onSetFilterForm
  } = useForm({
    selectedQuarter: () => getCurrentQuarter(),
    selectedYear: new Date().getFullYear(),
    selectedWeekEnding: ''
  });

  const [categoryOptions, setCategroyOptions] = useState([]);
  const [areaQuarters, setAreaQuarters] = useState([]);

  //To prevent unnecessary calls
  const [isSelectedWeekend, setIsSelectedWeekend] = useState(false);


  const {
    employees: employeesList,
    isLoadingEmployees
  } = useSelector(state => state.focalPoint);

  const responsible = useSelector(state => state.areaResponsible);

  const {
    isLoading: isLoadingCategories,
    data: categoriesList,
  } = useGetCategoriesQuery();

  const {
    isLoading: isLoadingAreaPoints,
    data: areaPointsList
  } = useGetRewardCategoriesListQuery({
    areaId: responsible.areaId,
    ...initialFilter
  }, {
    skip: initialFilter?.from === undefined
  });

  const { isLoading: isLoadingWeekendings, data: weekends } = useGetWeekEndsQuery(
    { ...initialFilter },
    {
      skip: initialFilter?.from === undefined || isSelectedWeekend
    }
  );

  const { isLoading: isLoadingAreaDetail, data: areaDetail } = useGetAreaQuery(
    responsible.areaId,
    { skip: responsible.areaId === "" }
  );

  const [createAreaPoint, { isLoading: isCreating, isSuccess }] = useCreateAreaPointsMutation();
  const [updateAreaPoint, { isLoading: isUpdating }] = useUpdateAreaPointMutation();

  const {
    isLoading: isLoadingNewEmployees
  } = useGetRewardEmployeesQuery({ skip: !isSuccess })

  const {
    formState: form,
    onSetForm,
    onResetForm,
    onInputChange: handleInputChange
  } = useForm({
    employeId: '',
    employee: '',
    activity: '',
    category: '',
    points: '',
    date: ''
  });

  const [errorValidations, setErrorValidations] = useState({
    points: '',
    date: ''
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'employeeName',
        header: "Employee",
        size: 140, //large column
      },
      {
        accessorKey: 'date',
        header: "Date",
        size: 120, //large column
      },
      {
        accessorKey: 'category',
        header: "Category",
        size: 100
      },
      {
        accessorKey: 'activity',
        header: "Activity",
        size: 100
      },
      {
        accessorKey: 'points',
        header: "Points",
        size: 90, //medium column
      },
    ],
    [],
  );

  const getAvailableYears = () => {
    const currentYears = availableYears().map((year) => ({
      value: year, label: year
    }));
    currentYears.unshift({ value: '', label: '' });
    setYears(currentYears);
  }

  const getAvailableQuarters = () => {
    const currentQuarters = availableQuarters().map((item) => ({
      value: item, label: item
    }));
    currentQuarters.unshift({ value: '', label: '' })
    setQuarters(currentQuarters);
  }

  const addNewAreaPoints = (form) => {
    const employee = employeesList.find(
      (user) => user.id === Number(form.employeId)
    );
    return {
      areaId: responsible.areaId,
      categoryId: categoriesList.find((cat) => 
        cat.categoryId === Number(form.category)
      ).categoryId,
      ssoEmployeeId: employee?.id,
      employeeName: employeeFullName(employee),
      employeeManagerId: employee.manager_id,
      employeeEmail: employee.email,
      activity: form.activity,
      points: Number(form.points),
      date: form.date
    }
  };

  const validateQuarterPoints = () => {
    const currentQty = getCurrentQuarter(form.date);
    const currentYear = new Date(form.date).getFullYear();
    const validateQuarter = areaQuarters.find(
      (qty) =>
        qty.quarter === currentQty && qty.year === currentYear && qty.points > 0
    );

    return !!validateQuarter;
  }

  const handleUpdate = async () => {
    if (form.points > areaPointsList.remainingPoints) {
      setErrorValidations((errorState) => ({
        ...errorState,
        points: `Points should not exceeded limit ${Number(areaPointsList.remainingPoints).toString()} points`
      }));
      toastError("Please review the required fields");
      return;
    }

    if (!validateQuarterPoints()) {
      setErrorValidations((errorState) => ({
        ...errorState,
        date: `You must select a date with assigned quarter points`
      }));
      toastError("Please review the required fields");
      return;
    }

    const formData = addNewAreaPoints(form);
    try {
      const { areaPointsId } = form;
      const body = { ...formData, areaPointsId };
      await updateAreaPoint({
        areaId: areaPointsId,
        body
      });
    } catch (error) {
      toastError("Error Update Area Point");
      throw new Error(`Error Update ${JSON.stringify(formData)}`);
    } finally {
      handleCloseModal();
      onResetForm();
    }
  }

  const handleSubmit = async () => {
    if (responsible.areaId === "") {
      toastError("Not have designated area");
      return;
    }
    if (form.points > areaPointsList.remainingPoints) {
      setErrorValidations((errorState) => ({
        ...errorState,
        points: `Points should not exceeded limit ${Number(areaPointsList.remainingPoints).toString()} points`
      }));
      toastError("Please fill out the required fields");
      return;
    }

    if (!validateQuarterPoints()) {
      setErrorValidations((errorState) => ({
        ...errorState,
        date: `You must select a date with assigned quarter points`
      }));
      toastError("Please fill out the required fields");
      return;
    }

    const formData = addNewAreaPoints(form);

    try {
      await createAreaPoint({
        areaId: responsible.areaId,
        body: formData
      });
    } catch (error) {
      toastError("Error creating Area Point");
      throw new Error(`Error sending data ${JSON.stringify(addNewAreaPoints(form))}`)
    } finally {
      setErrorValidations({
        points: '',
        date: ''
      })
      handleCloseModal();
    }
  }

  const handleModal = (type, data) => {
    if (type === 'new') {
      setOpenModal(true);
      setModalSettings({
        title: 'Reward Points',
        type
      })
    } else if (type === 'edit') {
      const { areaPointsId, categoryId } = data;

      setOpenModal(true);
      onSetForm({
        categoryId: categoryId ,
        category: categoryId.toString(),
        activity: data.activity,
        points: data.points,
        date: data.date,
        employee: data.employeeName,
        areaPointsId,
      });
      setPointsByCategory(data.points)
      setModalSettings({
        title: 'Edit Reward Points',
        type
      });
    }
  }

  const handleWeekendsFilters = async (selectedQuarter) => {
    const findQuarterFilter = quartersByYear.find(
      (quarter) => quarter.quarter === +selectedQuarter
    );
    setInitialFilter(findQuarterFilter);
  }

  const handleCloseModal = () => {
    setPointsByCategory(false);
    setOpenModal(false);
    onResetForm();
  }

  useEffect(() => {
    getAvailableYears();
    getAvailableQuarters();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (areaDetail) {
      const { quarters } = !!areaDetail && areaDetail;
      setAreaQuarters(quarters);
    }
  }, [areaDetail]);

  useEffect(() => {
    if (weekends) {
      const optionsWeekEnds = weekends['week-endings'].map((weekEnd) => ({
        value: formatDate(weekEnd),
        label: formatDate(weekEnd)
      }))
      optionsWeekEnds.unshift({ value: '', label: '' });
      setWeekEndings(optionsWeekEnds);
    }
  }, [weekends]);

  // Filter Handlers
  useEffect(() => {
    if (selectedYear) {
      setIsSelectedWeekend(false);

      const currentYear = new Date().getFullYear();
      const filterYears = initialFilters(selectedYear);
      setInitialFilter(filterYears);
      const quarters = getQuartersByYear(selectedYear);
      setQuartersByYear(quarters);

      if (selectedYear !== currentYear) {
        return;
      }

      onSetFilterForm({
        ...formState,
        selectedQuarter: getCurrentQuarter(),
        selectedWeekEnding: ''
      });
    } else {
      onSetFilterForm({
        selectedQuarter: '',
        selectedYear: '',
        selectedWeekEnding: ''
      });
      setInitialFilter({
        from: '',
        to: ''
      });
    }
    // eslint-disable-next-line
  }, [selectedYear]);

  const handleInitialFilter = async () => {
    const filters = initialFilters(selectedYear);
    setInitialFilter(filters);
    setWeekEndings([]);
  }

  useEffect(() => {
    setIsSelectedWeekend(false);

    if (!selectedQuarter && selectedYear) {
      handleInitialFilter();
      return;
    }

    if (selectedQuarter) {
      handleWeekendsFilters(selectedQuarter);
      return;
    }
    setWeekEndings([]);
    // eslint-disable-next-line
  }, [selectedQuarter]);

  useEffect(() => {
    if (!selectedWeekEnding) {
      if (selectedQuarter) {
        handleWeekendsFilters(selectedQuarter)
      }
      return;
    }

    if (selectedWeekEnding) {
      setIsSelectedWeekend(true);

      setInitialFilter((state) => (
        {
          ...state,
          to: selectedWeekEnding
        }));
    }
    // eslint-disable-next-line
  }, [selectedWeekEnding]);

  useEffect(() => {
    if (areaPointsList) {
      if (areaPointsList.areaPoints === undefined) {
        setEnableTable(false);
        return;
      }

      setEnableTable(true);
    }
  }, [areaPointsList])


  useEffect(() => {
    if (form.employee) {
      const found = optionUsernames.find((username) => username.label === form.employee);
      if (found) {
        const selectedEmployeeId = {
          target: {
            name: 'employeId',
            value: found.value.toString()
          }
        };
        handleInputChange(selectedEmployeeId)
      }
    }
    // eslint-disable-next-line
  }, [form.employee]);

  useEffect(() => {
    if (areaPointsList) {
      if (form.points > areaPointsList.remainingPoints) {
        setErrorValidations((errorState) => ({
          ...errorState,
          points: `Points should not exceeded limit ${Number(areaPointsList.remainingPoints).toString()} points`
        }));
      } else {
        setErrorValidations((errorState) => ({
          ...errorState,
          points: ``
        }));
      }
    }
    // eslint-disable-next-line
  }, [form.points]);

  useEffect(() => {
    if (form.date) {
      if (!validateQuarterPoints()) {
        setErrorValidations((errorState) => ({
          ...errorState,
          date: `You must select a date with assigned quarter points`
        }));
      } else {
        setErrorValidations((errorState) => ({
          ...errorState,
          date: ``
        }));
      }
    }
    // eslint-disable-next-line
  }, [form.date]);

  useEffect(() => {
    if (employeesList) {
      const filteredeEmployees = formatAllEmployees(employeesList);
      const formatUsersList = filteredeEmployees.map((user) => ({
        value: user.id,
        label: user.employeeName
      }));
      setOptionUserNames(formatUsersList)
    }
    // eslint-disable-next-line
  }, [employeesList]);

  useEffect(() => {
    if (categoriesList) {
      const formatCategoryList = categoriesList.filter(
        (category) => category.automatic !== true)
        .map(
          (category) => ({
            label: category.description,
            value: category.categoryId
          }));

      formatCategoryList.unshift(({
        label: '',
        value: ''
      }));
      setCategroyOptions(formatCategoryList);
    }
  }, [categoriesList]);

  /**
   * Functionality: If the selected category has points assigned to it, 
   * set the points in form and input and disable the input
   * @param {*} categories CategoriesList
   * @param {*} categoryId Category combo target value
   */
  const setDefaultPoints  = (categories, categoryId) => {
    if (categories && categories.length > 0) {
      const disablePointsInput = categories.find(
        (cat) => 
          cat.categoryId === Number(categoryId) &&
          cat.points !== 0
      );
      
      if (disablePointsInput) {
        setPointsByCategory(disablePointsInput.points)
        onSetForm({
          ...form,
          category: categoryId,
          points: disablePointsInput.points
        });
      } else {
        setPointsByCategory(false);
        onSetForm({
          ...form,
          category: categoryId,
          points: 0
        });
      }
    }
  }

  return ((isLoadingAreaDetail ||
    isLoadingWeekendings ||
    isLoadingAreaPoints ||
    isCreating ||
    isLoadingCategories ||
    isUpdating ||
    isLoadingNewEmployees ||
    isLoadingEmployees) ? <Loader /> :
    <>
      <div className="animate__animated animate__fadeIn">
        <Title>
          <Logout title={`Area Points - ${responsible?.areaName || ''}`} />
        </Title>
        <section className="points-by-employee">
          <div className="points-by-employee__info">
            <div>
              <strong>Manager:</strong>
              <span>{`${user?.name} ${user?.lastName}`}</span>
            </div>
            <div>
              <strong>Remaining Points:</strong>
              {
                !!areaPointsList?.remainingPoints && (
                  <>
                    <span>{areaPointsList?.remainingPoints}</span>
                  </>
                )
              }
            </div>
          </div>
        </section>
        <div className="content-controls">
          <Button block icon={faPlusCircle} onClick={() => { handleModal('new') }}>New</Button>
          <div className="toggle-control">
            <strong>Filters</strong>
            <Toggle
              onClick={() => setFilters(!filters)}
              toggled={filters} />
          </div>
          <div className="toggle-control">
            <strong>Search bar</strong>
            <Toggle
              onClick={() => setSearchBar(!searchBar)}
              toggled={searchBar} />
          </div>
        </div>
        {filters && (
          <Filters
            selectedQuarter={selectedQuarter}
            selectedYear={selectedYear}
            selectedWeekEnding={selectedWeekEnding}
            years={years}
            quarters={quarters}
            weekEndings={weekEndings}
            initialFilter={initialFilter}
            handleSelect={handleSelect}
          />
        )
        }
      </div>
      {/* <ScrollerContainer> */}
      <MaterialReactTable
        title="Employees"
        enableColumnActions={false}
        enableColumnResizing
        enableStickyHeader
        muiTableContainerProps={{
          sx: {
            height: {
              lg: '225px',
              xl: '550px'
            }
          }
        }}
        // enableColumnFilters={false}
        // enablePagination={false}
        // enableSorting={false}
        // enableBottomToolbar={false}
        enableGlobalFilterModes
        initialState={{
          showGlobalFilter: true,
        }}
        positionGlobalFilter="left"
        muiSearchTextFieldProps={{
          placeholder: `  Search`,
          sx: { minWidth: '400px' },
        }}
        enableTopToolbar={searchBar}
        columns={columns}
        data={
          !enableTable || !initialFilter.from ? [] : areaPointsList.areaPoints
        }
        muiTableBodyCellProps={{
          sx: {
            fontSize: '16px',
            fontFamily: 'AvantGardeMedium',
            padding: '.5rem'
          },
        }}
        // muiTableBodyP
        muiTableHeadCellProps={{
          sx: {
            fontWeight: 'bold',
            fontSize: '16px',
            fontFamily: 'AvantGardeMedium'
          },
        }}
        muiTableBodyProps={{
          sx: {
            '& tr:nth-of-type(odd)': {
              backgroundColor: '#f5f5f5'
            },
          },
        }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <div className="redeem-points-points__actions">
            <Button onClick={() => { handleModal("edit", row.original); }}>
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          </div>
        )}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: 'Actions',
            muiTableHeadCellProps: {
              align: 'center', //change head cell props
            },
            size: 110,
          },
        }}
      />
      {/* </ScrollerContainer> */}

      <Modal open={openModal} onClose={() => handleCloseModal()}>
        <Title>{modalSettings?.title}</Title>
        <div className="coe-setup__form">
          <Form classes="form center" onSubmit={
            modalSettings.type === 'new' ? () => handleSubmit() : () => handleUpdate()}>
            <Label type="grid">
              Employee
              <InputSelect
                name="employee"
                autoComplete="off"
                placeholder="Type a name ..."
                required
                onChange={(e) => handleInputChange(e)}
                disabled={modalSettings.type === "edit"}
                value={form.employee}
                options={optionUsernames} />
            </Label>
            <Label type="grid">
              Category
              <Select
                selectedValue={form.category}
                onChange={(e) => {
                  handleInputChange(e)
                  setDefaultPoints(categoriesList, e.target.value)
                }}
                name="category"
                value={form.category}
                required
                options={categoryOptions}
              />
            </Label>
            <Label type="grid">
              Activity
              <Input
                type="text"
                placeholder="Activity Description"
                value={form.activity}
                name="activity"
                required
                onChange={(e) => handleInputChange(e)}
              />
            </Label>
            <Label type="grid">
              Points
              <Input
                type="number"
                placeholder="points"
                name="points"
                min="0"
                required
                autoComplete="off"
                value={
                  pointsByCategory && pointsByCategory !== 0 
                    ? Number(pointsByCategory).toString()
                    : Number(form.points).toString()
                }
                onChange={(e) => handleInputChange(e)}
                disabled={pointsByCategory}
              />
            </Label>
            <div className="message">
              <center>
                <small className="warning">
                  {errorValidations.points}
                </small>
              </center>
            </div>
            <Label type="grid">
              Date
              <InputDate
                name="date"
                value={form.date}
                required
                min={formatDateYear(initialFilter?.from)}
                max={formatDateYear(initialFilter?.to)}
                onChange={(e) => handleInputChange(e)}
              />
            </Label>

            <div className="message">
              <center>
                <small className="warning">
                  {errorValidations.date}
                </small>
              </center>
            </div>
            <div className="form__actions">
              <Button
                disabled={isCreating || isUpdating}
                type="button"
                secondary
                onClick={(e) => handleCloseModal()} >
                Cancel
              </Button>
              <Button
                disabled={isCreating || isUpdating}
                type="submit" >Save</Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>

  );
};

export default CoE;