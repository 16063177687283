
const addLeftZero = (digit) => {
  return digit < 10 ? `0${digit}` : digit
}

/**
 * Format into MM-dd-yyyy
 * @param {Date} date 
 * @returns {String}
 */
export const formatDate = (date) => {
  const currentDate = new Date(
    new Date(date).toUTCString()
  );

  return `${addLeftZero(currentDate.getUTCMonth() + 1)
    }/${addLeftZero(currentDate.getUTCDate())
    }/${currentDate.getUTCFullYear()}`
}

export const availableYears = () => {
  const until = (new Date().getUTCFullYear() - 1);
  const years = [];
  for (let i = until; i <= new Date().getUTCFullYear() + 2; i++) {
    years.push(i)
  }
  return years;
}

export const intersection = (...arrays) => {
  return arrays.reduce((a, b) => a.filter(c => b.includes(c)));
}

export const availableQuarters = () => {
  const until = 1;
  const quarters = [];
  for (let i = until; i <= 4; i++) {
    quarters.push(i)
  }
  return quarters;
}

/**
 * Date Format in YYYY-mm-dd
 * @param {String} date 
 * @returns 
 */
export const formatDateYear = (date) => {
  const currentDate = new Date(date);

  return `${currentDate.getUTCFullYear()
    }-${addLeftZero(currentDate.getUTCMonth() + 1)
    }-${addLeftZero(currentDate.getUTCDate())}`
}

/**
 * groupBy, key should be string key-object
 * @param {Array<String>} array 
 * @param {String} key 
 * @returns {Array<String>}
 */
export const groupBy = (array, key) => {
  return array.reduce((accumulator, current) => {
    const groupKey = current[key];

    if (groupKey in accumulator) {
      accumulator[groupKey].push(current);
    } else {
      accumulator[groupKey] = [current];
    }
    return accumulator;
  }, {});
};

export const initialFilters = (year) => {
  const currentYear = !year ? new Date().getFullYear() : year;

  const january = 0;
  const firstDay = new Date(currentYear, january, 1);

  const december = 11;
  const lastDay = new Date(currentYear, december, 31);

  return {
    from: formatDate(firstDay),
    to: formatDate(lastDay)
  }
}

export const getQuartersByYear = (year) => {
  const quarters = [];

  for (let i = 0; i < 4; i++) {
    const startMonth = i * 3;
    const startDate = new Date(year, startMonth, 1);
    const endMonth = startMonth + 2;
    const endDate = new Date(year, endMonth + 1, 0);

    quarters.push({
      startDate,
      endDate
    });
  }

  return quarters.map(
    ({ startDate, endDate }, index) => ({
      quarter: index + 1,
      from: formatDate(startDate),
      to: formatDate(endDate),
    }))
}

export const diffTime = (lastTime, now) => {
  let diff = (lastTime - now) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export const employeeFullName = (employee) => {
  return employee.second_name ?
    `${employee.first_name} ${employee.second_name} ${employee.last_name}`
    : `${employee.first_name} ${employee.last_name}`
}

export const getCurrentQuarter = (date) => {
  const currentDate = date ? new Date(date) : new Date();
  const currentMonth = currentDate.getMonth();
  return Math.floor(currentMonth / 3) + 1;
}

/**
 * Order By Key
 * @param {Array<String>} array 
 * @param {String} key 
 * @returns {Array<String>}
 */
export const orderBy = (array, key) =>
  array.sort((a, b) => {
    if (a[key] > b[key]) {
      return 1;
    }
    if (a[key] < b[key]) {
      return -1;
    }
    return 0;
  });

export const formatAllEmployees = (employees) => {
  const transformedEmployees = employees.map((employee) => ({
    id: employee.id,
    employeeName: employeeFullName(employee)
  }));
  const filtered = transformedEmployees.filter((item, index) => {
    return index === transformedEmployees.findIndex(
      (employee) => item.id === employee.id)
  });
  return filtered;
}
