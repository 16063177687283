import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./slices/auth";
import { focalPointSlice } from "./slices/focalPoint";
import { rewardCategoryApi } from "./slices/rewardCategory";
import { areaSetupApi } from "./slices/areaSetup";
import { areaResponsibleSlice } from "./slices/areaResponsible";
import { areaPointsApi } from "./slices/areaPoints";
import { pointsByEmployeeApi } from "./slices/pointsByEmployee/apis/pointsByEmployeeApi";
import { redeemPointsApi } from "./slices/redeemPoints";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    focalPoint: focalPointSlice.reducer,
    areaResponsible: areaResponsibleSlice.reducer,
    [rewardCategoryApi.reducerPath]: rewardCategoryApi.reducer,
    [areaSetupApi.reducerPath]: areaSetupApi.reducer,
    [areaPointsApi.reducerPath]: areaPointsApi.reducer,
    [pointsByEmployeeApi.reducerPath]: pointsByEmployeeApi.reducer,
    [redeemPointsApi.reducerPath]: redeemPointsApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(rewardCategoryApi.middleware)
    .concat(areaSetupApi.middleware)
    .concat(areaPointsApi.middleware)
    .concat(pointsByEmployeeApi.middleware)
    .concat(redeemPointsApi.middleware)
});
