import * as FileSaver from "file-saver";
import XLSX from 'sheetjs-style';

import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { headers } from "../../../utils/constants";

export const ExcelExport = ({ data, conversionFactor }) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const extension = '.xlsx';

  const transformedData = data.map((item) => {
    const { ssoEmployeeId, employeeIdKey, ...rest } = item;
    return rest;
  });

  const dataFormated = transformedData?.map((d) => {
    const header = {};
    Object.keys(d).forEach((key) => {
      header[headers[key]] = d[key]
    });

    return ({
      ...header
    });
  });

  const convertedBalance = () => {
    return dataFormated.map((point) => {
      if (point['Current Balance'] < 0) {
        return {
          ...point,
          'USD $': 0
        }
      }
      return {
        ...point,
        'USD $': point['Current Balance'] * conversionFactor
      }
    })
  }

  const styleHeader = {
    font: {
      sz: 12,
      bold: true,
      color: {
        rgb: "040C2C"
      }
    },
  };

  const exportXlsx = () => {
    const generalBalance = convertedBalance()
    const ws = XLSX.utils.json_to_sheet(generalBalance);
    const maxWidth = generalBalance.reduce(
      (w, r) => Math.max(w, r['Employee Name'].length), 30
    );

    ws["!cols"] = [{
      wch: maxWidth
    }];

    ws["A1"].s = styleHeader;
    ws["B1"].s = styleHeader;
    ws["C1"].s = styleHeader;
    ws["D1"].s = styleHeader;
    ws["E1"].s = styleHeader;

    const wb = {
      Sheets: {
        'Points Earned': ws
      },
      SheetNames: ['Points Earned']
    };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, 'PointsEarned' + extension);
  }

  return (
    <FontAwesomeIcon
      className="export-excel-btn"
      onClick={exportXlsx}
      size={"2xl"}
      icon={faFileExcel}
    />
  );
}
