// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "PROVIDER": process.env.REACT_APP_PROVIDER,
    "REGION": process.env.REACT_APP_REGION,
    "USER_POOL_ID": process.env.REACT_APP_USER_POOL_ID,
    "USER_POOL_APP_CLIENT_ID": process.env.REACT_APP_USER_POOL_APP_CLIENT_ID,
    "OAUTH_DOMAIN": process.env.REACT_APP_OAUTH_DOMAIN,
    "OAUTH_SCOPE": ["phone", "email", "openid", "profile"],
    "OAUTH_REDIRECT_SIGN_IN": process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
    "OAUTH_REDIRECT_SIGN_OUT": process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
    "OAUTH_RESPONSE_TYPE": "code" //or token
}