import React, { useContext } from "react";
import useAuth from "../hooks/useAuth";
import awsExports from "../config/aws-exports";
import Button from './Button';
import Title from "./Title";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import userContext from "../config/contextUser";
import { ReactComponent as NearshoreLogo } from '../images/logo.svg'
import "./Login.scss";
import Loader from "./Loader";

export default function Login() {
    const isLoading = useContext(userContext).loading;

    const { signIn } = useAuth({
        provider: awsExports.PROVIDER,
        options: {
            userPoolId: awsExports.USER_POOL_ID,
            userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
            oauth: {
                domain: awsExports.OAUTH_DOMAIN,
                scope: awsExports.OAUTH_SCOPE,
                redirectSignIn: awsExports.OAUTH_REDIRECT_SIGN_IN,
                redirectSignOut: awsExports.OAUTH_REDIRECT_SIGN_OUT,
                region: awsExports.REGION,
                responseType: awsExports.OAUTH_RESPONSE_TYPE
            },
            federatedSignIn: {
                customProvider: awsExports.PROVIDER
            }
        }
    });

    return (
        <section className="login animate__animated animate__fadeIn">
            {isLoading && <Loader />}
            <div className="login-container">
                <div className="login-logo">
                    <NearshoreLogo />
                </div>
                <Title isCentered>
                    Access Rewards System
                </Title>
                <div className="login-buttons">
                    <Button type="button" icon={faRightToBracket} onClick={() => signIn()}>Login</Button>
                </div>
            </div>
        </section>
    )
}