import { useState } from "react";

export const useLocalStorage = (keyName, defaultValue) => {
	const [storedValue, setCurrentValue] = useState(() => {
		try {
			const item = localStorage.getItem(keyName);
			return item ? JSON.parse(item) : defaultValue;
		} catch (error) {
			return defaultValue;
		}
	});

	const setValue = (value) => {
		try {
			const valueToStore =
				value instanceof Function ? value(storedValue) : value;
			setCurrentValue(valueToStore);
			if (typeof window !== "undefined") {
				if(typeof valueToStore !== "object") {
					localStorage.setItem(keyName, valueToStore);
				} else {
					localStorage.setItem(keyName, JSON.stringify(valueToStore));
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	const removeItem = (keyName) => {
		try {
			localStorage.removeItem(keyName);
		} catch (error) {
			console.log(error);
		}
	}

	return { storedValue, setValue, removeItem };
}
