import React from "react";
import "./Label.scss";

const Label = ({type, children}) => {
    return (
        <label className={`${type === 'grid' ? 'grid' : type} ${type === 'flex ' ? 'flex' : ''}`}>
            {children}
        </label>
    )
}

export default Label;