import axios from 'axios';
import { FOCALPOINT_RESOURCE, URL_ENDPOINT, URL_ENDPOINT_FOCAL_POINT } from './constants';
import { StorageItems } from '../utils/constants';
import { toast } from 'react-toastify';

const axiosInstance = axios.create({
  baseURL: URL_ENDPOINT,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

// Use dynamic URL (FocalPoint API and Reward API)
axiosInstance.interceptors.request.use(request => {
  if (request.url.includes(FOCALPOINT_RESOURCE)) {
    request.baseURL = URL_ENDPOINT_FOCAL_POINT;
  }
  try {
    const accessToken = setDefaultHeader();
    request.headers.Authorization = accessToken;
  } catch (error) {
    return Promise.reject(error);
  }
  return request;
}, error => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    localStorage.clear();
    toast.error("Session has expired", {
      position: toast.POSITION.TOP_RIGHT,
      progress: 0
    });
    window.location.href = '/';
  }
  return Promise.reject(error);
});

const setDefaultHeader = () => {
  const accessToken = localStorage.getItem(StorageItems.ACCESS_TOKEN);
  axiosInstance.defaults.headers.common['Authorization'] = accessToken;
  return accessToken;
}

(async function () {
  try {
    setDefaultHeader();
  } catch (error) {
    throw new Error(
      `does not exist localStorageItem ${StorageItems.ACCESS_TOKEN}`
    );
  }
})();

export default axiosInstance;
