import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: null,
    isAuthenticated: false,
    isSignIn: false,
    isAccessTokenRequest: false,
    errorMessage: null
  },
  reducers: {
    startSignIn: (state) => {
      state.isSignIn = true;
    },
    startAccessToken: (state) => {
      state.isAccessTokenRequest = true;
    },
    endAccessToken: (state, action) => {
      state.isAccessTokenRequest = false;
      state.errorMessage = action.payload;
    },
    setAccessToken: (state, action) => {
      const { payload } = action;
      state.accessToken = payload;
      state.isAuthenticated = true;
      state.isSignIn = false;
      state.isAccessTokenRequest = false;
    },
    setLogout: (state) => {
      state.initialState = null;
      state.isAuthenticated = false;
    }
  }
});
// Action creators are generated for each case reducer function
export const {
  setAccessToken,
  setLogout,
  startSignIn,
  startAccessToken,
  endAccessToken
} = authSlice.actions;
