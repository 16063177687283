import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './Autocomplete.scss';

const Autocomplet = ({ ...props }) => {
  return (
    <Autocomplete
      multiple
      id="tags-standard"
      {...props}
      disableClearable
      handleHomeEndKeys
      renderInput={(params) => (
        <TextField className="position_select"
          {...params}
          variant="standard"
          label="Employees"
          placeholder="Add"
        />
      )}
    />
  );
};

export default Autocomplet;