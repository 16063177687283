import './Card.scss';

function Card({ children, isMain }) {

    return (
        <section id="card" className={`card ${isMain ? "card--main" : ""}`}>
            {children}
        </section>
    )
}

export default Card;