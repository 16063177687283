import { useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { usePointsEarnedByEmployeeQuery } from '../../../store/slices/pointsByEmployee/apis/pointsByEmployeeApi';

export const DetailsPointsEmpoyee = ({ employeId, filters }) => {

  const columnPoints = useMemo(() => [
    {
      accessorKey: 'date',
      header: "Date"
    },
    {
      accessorKey: 'category',
      header: "Category"
    },
    {
      accessorKey: 'activity',
      header: "Activity"
    },
    {
      accessorKey: 'points',
      header: "Earned points"
    },
  ], []);

  const { isLoading, data: details } = usePointsEarnedByEmployeeQuery(
    { employeId, ...filters },
    { skip: !filters.hasOwnProperty('from') }
  )

  return (isLoading ? <center>
    <p>Loading details ...</p>
  </center> : <>
    <MaterialReactTable
      title="Points"
      enableColumnActions={false}
      enableColumnFilters={false}
      enableSorting={true}
      enableTopToolbar={false}
      columns={columnPoints}
      data={details}
      muiTableBodyCellProps={{
        sx: {
          fontSize: '16px',
          fontFamily: 'AvantGardeMedium',
          padding: '.5rem'
        },
      }}
      muiTableBodyP
      muiTableHeadCellProps={{
        sx: {
          fontWeight: 'bold',
          fontSize: '16px',
          fontFamily: 'AvantGardeMedium'
        },
      }}
      muiTableBodyProps={{
        sx: {
          '& tr:nth-of-type(odd)': {
            backgroundColor: '#f5f5f5'
          },
        },
      }}
    />
  </>)
}
