import React from 'react';
import "./Select.scss";

function Select(props) {
  let auxBase;

  if (props.hasOwnProperty('selectedValue')) {
    const { selectedValue, ...base } = props;
    auxBase = base;
  }

  return (
    <select disabled={props.disabled} name={props.name} value={props.selectedValue} onChange={props.onChange} className="select" {...auxBase}>
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

export default Select;