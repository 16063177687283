import { formatDateYear } from '../utils/utils';
import './InputDate.scss';


export const InputDate = ({ value, ...props }) => {
  return (
    <div className='input-date'>
      <div className='label-content'>
        <p className='label-date'>{value ? formatDateYear(value) : 'mm/dd/yyyy'}</p>
        <input type='date' placeholder="MM/DD/YYYY" {...props} value={value} />
      </div>
    </div>
  )
}
