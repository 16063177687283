import { URL_ENDPOINT_FOCAL_POINT } from "../../../API/constants";
import { focalPointApi } from "../../../API/focalPointApi";
import { setEmployees, startLoadingEmployees } from "./focalPointSlice"

export const getAllEmployees = () => {
  return async (dispatch, getState) => {
    dispatch(startLoadingEmployees());

    const { accessToken } = getState().auth;

    const { data } = await focalPointApi({
      baseURL: URL_ENDPOINT_FOCAL_POINT,
      url: `/employee/all`,
      headers: {
        Authorization: accessToken
      }
    });

    dispatch(setEmployees({ employees: data }));
  }
}
