import { useEffect, useState } from "react";
import Form from "../../../components/Form";
import Input from "../../../components/Input";
import Label from "../../../components/Label";
import Select from "../../../components/Select";
import { useForm } from "../../../hooks/useForm";
import { availableYears } from "../../../utils/utils";
import { optionsQuarter } from "../../../utils/constants";
import { useCreateQuarterMutation, useGetAreaQuery, useGetQuartersByAreaQuery, useUpdateQuarterMutation } from "../../../store/slices/areaSetup";
import Loader from "../../../components/Loader";
import { TransitionQuarter } from "./TransitionQuarter";
import Button from "../../../components/Button";
import { toastError, toastSuccess } from "../../../utils/alerts";

export const AreaQuartersSetup = ({ areaId, onCloseModal }) => {
  const [optionsYear, setOptionYear] = useState([]);
  const [isTransition, setTransition] = useState(false);

  const {
    formState: quarterForm,
    onSetForm: onSetQuarterForm,
    onInputChange: handleQuarterInputChange
  } = useForm({
    year: "",
    quarter: "",
    points: 0
  });

  const { isLoading: isLoadingQuartes, data: quarterByArea } = useGetQuartersByAreaQuery(areaId);

  const [createQuarter, { isLoading: isCreating, isSuccess: isSuccessAdded }] = useCreateQuarterMutation();
  const [updateQuarter, { isLoading: isUpdating, isSuccess: isSuccessUpdated }] = useUpdateQuarterMutation();
  const { isLoading: isLoadingAfterUpdate } = useGetAreaQuery(areaId, {
    skip: !isSuccessUpdated || !isSuccessAdded
  });
  const [errorValidations, setErrorValidations] = useState({
    year: ''
  });

  const getYears = () => {
    const years = availableYears();
    const transformOptions = years.map((year) => ({
      value: year, label: year
    }));

    transformOptions.unshift({
      value: '', label: ''
    })
    setOptionYear(transformOptions);
  }

  const handleSubmit = async () => {
    try {
      if (quarterForm.quarterId) {
        await updateQuarter({ areaId, body: quarterForm });
        toastSuccess("Changes successfully saved");
        onSetQuarterForm(quarterForm);
        return;
      }
      const body = {
        ...quarterForm,
        areaId
      }
      await createQuarter({ areaId, body });
      toastSuccess("Changes successfully saved");
    } catch (error) {
      toastError("Error during save changes");
      throw new Error(error);
    }
  }

  const onCancelQuarter = () => {
    setTransition(false);
    const { quarter, areaId, year } = quarterForm;

    const findQuarter = findQuarterArea(+quarter, year);
    if (areaId !== '' && findQuarter) {
      onSetQuarterForm({ ...quarterForm, points: findQuarter.points })
      return;
    }
    onSetQuarterForm({ ...quarterForm, points: '' })
  }

  const findQuarterArea = (quarter, year) =>
    quarterByArea.find(
      (q) => q.quarter === +quarter && year === q.year
    );

  // Quarter Validations
  useEffect(() => {
    getYears();
    if (quarterByArea) {
      // Set on Current Quarter after updated it
      if (quarterForm.areaId) {
        const { year, quarter } = quarterForm;
        const find = findQuarterArea(quarter, year);
        onSetQuarterForm(find);
        return;
      }

      if (quarterByArea.length > 0) {
        onSetQuarterForm(quarterByArea[0]);
      }
    }
    // eslint-disable-next-line
  }, [quarterByArea]);

  useEffect(() => {
    if (quarterForm) {
      if (quarterForm.quarter) {
        const { quarter, areaId, year } = quarterForm;

        if (!areaId || !quarter) {
          return;
        }

        const findQuarter = findQuarterArea(+quarter, year);
        if (isTransition) {
          return;
        }
        if (findQuarter) {
          onSetQuarterForm(findQuarter);
        } else {
          onSetQuarterForm({
            ...findQuarter,
            areaId,
            quarter,
            points: '',
            year
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [quarterForm.quarter]);

  useEffect(() => {
    if (quarterForm) {
      if (quarterForm.points > 0) {
        const { quarter, year } = quarterForm;
        const findQuarter = findQuarterArea(+quarter, year);

        if (!findQuarter) {
          setTransition(false);
          if (quarterForm.points > 0) {
            setTransition(true);
          }
          return;
        }

        if (+quarterForm.points !== findQuarter.points) {
          setTransition(true);
        } else {
          setTransition(false);
        }
        return;
      }
      setTransition(false);
    }
    // eslint-disable-next-line
  }, [quarterForm.points]);

  useEffect(() => {
    if (quarterForm.year) {

      const { year } = quarterForm;
      const findByYear = quarterByArea?.find(
        (quarter) => quarter.year === +year
      );
      if (isTransition) {
        return;
      }
      if (!findByYear) {
        onSetQuarterForm({
          quarter: 1,
          year,
          points: ''
        });
      } else {
        onSetQuarterForm(findByYear);
      }
      if (year) {
        setErrorValidations((errorState) => ({
          ...errorState,
          year: ""
        }))
      }
    }
    // eslint-disable-next-line
  }, [quarterForm.year]);

  return (
    (isLoadingQuartes || isLoadingAfterUpdate) ? <Loader /> :
      <section className="coe-setup__form">
        <Form classes="center" onSubmit={() => handleSubmit()}>
          <Label type="grid">
            Year
            <Select
              name="year"
              // disabled={isTransition || savingQuarter}
              disabled={isTransition || isCreating || isUpdating}
              value={quarterForm.year}
              selectedValue={quarterForm.year}
              options={optionsYear}
              onChange={(e) => handleQuarterInputChange(e)}
            />
          </Label>
          <div className="message">
            <small className="warning">
              {errorValidations.year}
            </small>
          </div>
          <Label type="grid">
            Quarter
            <Select
              selectedValue={quarterForm.quarter}
              name="quarter"
              // disabled={isTransition || savingQuarter}
              disabled={isTransition || isCreating}
              value={quarterForm.quarter}
              options={optionsQuarter}
              onChange={(e) => handleQuarterInputChange(e)}
            />
          </Label>
          <Label type="grid">
            Quarterly Points
            <Input type="number"
              placeholder="Points"
              name="points"
              autoComplete="off"
              min={0}
              required
              disabled={isCreating || isUpdating}
              value={quarterForm.points}
              onChange={(e) => handleQuarterInputChange(e)}
            />
          </Label>
          {(isTransition) &&
            <TransitionQuarter savingQuarter={isCreating || isUpdating} />
          }
          <div className="form__actions">
            <Button
              type="button"
              disabled={isCreating || isUpdating}
              secondary
              onClick={
                (e) => isTransition ? onCancelQuarter() : onCloseModal()}>
              Cancel
            </Button>
            <Button type="submit" disabled={isCreating || isUpdating} >
              Save
            </Button>
          </div>
        </Form>
      </section>
  )
}
