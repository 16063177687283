import { createSlice } from '@reduxjs/toolkit';

export const areaResponsibleSlice = createSlice({
  name: 'areaResponsible',
  initialState: {
    areaId: "",
    areaName: "",
    isLoadingAreaResponsible: false
  },
  reducers: {
    startLoadingResponsible: (state, /* action */) => {
      state.isLoadingAreaResponsible = true;
    },
    setAreaResponsible: (state, action) => {
      state.areaId = action.payload.areaId;
      state.areaName = action.payload.areaName;
      state.isLoadingAreaResponsible = false;
    }
  }
});
// Action creators are generated for each case reducer function
export const { startLoadingResponsible, setAreaResponsible } = areaResponsibleSlice.actions;