import { orderBy } from "../../../../utils/utils";
import { areaPointsApi } from "../../areaPoints/apis/areaPointsApi";

export const areaSetupApi = areaPointsApi.injectEndpoints({
  endpoints: (builder) => ({
    // Areas Endpoints
    getAreas: builder.query({
      query: () => '/areas',
      transformResponse: (response) => orderBy(response, 'areaName'),
      providesTags: (result) =>
        result ? [
          ...result.map(({ id }) => ({
            type: 'Area', id
          })),
          { type: 'Area', id: 'LIST' }
        ] : [
          { type: 'Area', id: 'LIST' }
        ]
    }),
    getArea: builder.query({
      query: (areaId) => `/areas/${areaId}`,
      providesTags: (result) =>
        [
          { type: 'Area', id: 'DETAIL' },
          { type: 'Quarter', id: 'DETAIL' },
        ]
    }),
    createAreaSetUp: builder.mutation({
      query: ({ body }) => ({
        url: '/areas',
        method: 'POST',
        body
      }),
      invalidatesTags: [
        { type: 'Area', id: 'LIST' },
        { type: 'Quarter', id: 'DETAIL' },
      ]
    }),
    updateAreaSetUp: builder.mutation({
      query: ({ areaId, body }) => ({
        url: `/areas/${areaId}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: (result, error, { areaId }) =>
        [
          { type: 'Area', id: 'LIST' },
          { type: 'Quarter', id: 'DETAIL' },
          { type: 'Area', id: 'DETAIL' }
        ]
    }),
    getQuartersByArea: builder.query({
      query: (areaId) => `/areas/${areaId}/quarters`,
      transformResponse: (response) => {
        if (response.length > 0) {
          return response.sort((a, b) => a.quarter - b.quarter)
        }
        return response;
      },
      providesTags: (result, error, { areaId }) => [
        { type: 'Quarter', id: 'DETAIL' },
        { type: 'Quarter', id: areaId }
      ]
    }),
    createQuarter: builder.mutation({
      query: ({ areaId, body }) => ({
        url: `/areas/${areaId}/quarters`,
        method: 'POST',
        body
      }),
      invalidatesTags: [
        { type: 'Quarter', id: 'DETAIL' },
        { type: 'AreasSetup', id: 'UPDATED' },
      ]
    }),
    updateQuarter: builder.mutation({
      query: ({ areaId, body }) => ({
        url: `/areas/${areaId}/quarters`,
        method: 'PUT',
        body
      }),
      invalidatesTags: (result, error, { areaId }) =>
        [
          { type: 'Quarter', id: 'DETAIL' },
          { type: 'AreasSetup', id: 'UPDATED' },
          { type: 'Quarter', id: areaId }
        ]
    }),
  }),
})

export const {
  useGetAreasQuery,
  useGetAreaQuery,
  useGetQuartersByAreaQuery,
  useCreateQuarterMutation,
  useCreateAreaSetUpMutation,
  useUpdateAreaSetUpMutation,
  useUpdateQuarterMutation
} = areaSetupApi;
