import { createContext } from "react";

const contextUser = createContext({
    user: null,
    setUser: (userData) => { },
    loading: true,
    setLoading: (boolLoading) => { },
    setRoutes: (routes) => { },
    routes: null,
    userRoutes: null,
    setUserRoutes: (userRoutes) => { }
});

export default contextUser;