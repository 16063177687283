import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URL_ENDPOINT } from "../../../../API/constants";
import { orderBy } from "../../../../utils/utils";

export const pointsByEmployeeApi = createApi({
  reducerPath: 'pointsByEmployee',
  tagTypes: ['Reward'],
  baseQuery: fetchBaseQuery({
    baseUrl: URL_ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.accessToken;
      if (token) {
        headers.set('Authorization', token);
      }
    }
  }),
  endpoints: (builder) => ({
    getPointsByManager: builder.query({
      query:
        ({ managerId, from, to }) => `/points/managers/${managerId}?startdate=${from}&enddate=${to}`,
      transformResponse: (response) => orderBy(response, 'employeName').map(
        (points) => {
          if (points.redeemedPoints === null) {
            points.redeemedPoints = 0;
          }
          return points
        }),
      providesTags: [{
        type: 'Redeem', id: 'UPDATED'
      }]
    }),
    pointsEarnedByEmployee: builder.query({
      query:
        ({ employeId, from, to }) =>
          `/points/employees/${employeId}?startdate=${from}&enddate=${to}`,
    }),
    getAllEmployeesPoints: builder.query({
      query:
        ({ from, to }) => `/points/employees?startdate=${from}&enddate=${to}`,
      transformResponse: (response) => orderBy(response, 'employeName').map(
        (points) => {
          if (points.redeemedPoints === null) {
            points.redeemedPoints = 0;
          }
          return points
        }),
      providesTags: [{
        type: 'Redeem', id: 'UPDATED'
      }]
    }),
    getPointsConversionFactor: builder.query({
      query: () => '/points/conversion',
    })
  })
});

export const {
  useGetPointsByManagerQuery,
  useGetAllEmployeesPointsQuery,
  usePointsEarnedByEmployeeQuery,
  useGetPointsConversionFactorQuery
} = pointsByEmployeeApi;
