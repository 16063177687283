import React from "react";
import "./Loader.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
library.add( faSpinner );

const Loader = () => {
    return (
        <section className="loader">
            <div className="loader-spinner">
                <FontAwesomeIcon icon="spinner" spin />
            </div>
        </section>
    )
}

export default Loader;