import React, { useState, useMemo, useEffect } from "react";
import MaterialReactTable from 'material-react-table';
import Input from "../../components/Input";
import Form from "../../components/Form";
import Modal from "../../components/Modal";
import Select from "../../components/Select";
import Button from "../../components/Button";
import Title from "../../components/Title";
import './css/RewardCategory.scss';
import Label from "../../components/Label";
import { faPlusCircle, faPencil } from "@fortawesome/free-solid-svg-icons";
import { Checkbox } from "@mui/material";
import { useForm } from "../../hooks/useForm";
import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Logout } from "../../components/Logout";
import { toastError } from "../../utils/alerts";
import {
  useCreateCategoryMutation,
  useGetCategoriesQuery,
  useGetCategoryMeasuresQuery,
  useUpdateCategoryMutation
} from "../../store/slices/rewardCategory/apis/rewardCategoryApi";
import { CategoryMapping } from "../../utils/mappings";

const RewardCategoryCatalog = () => {
  const [openModal, setOpenModal] = useState(false);
  const [measureOptions, setMeasureOptions] = useState([]);

  const [modalSettings, setModalSettings] = useState({
    type: '',
    text: ''
  });
  const [errorValidations, setErrorValidations] = useState({
    description: '',
    points: ''
  });

  const {
    data: measureData,
    isLoading: isLoadingMeasure
  } = useGetCategoryMeasuresQuery();
  const {
    isLoading: isLoadingCategories,
    data: categoriesList,
  } = useGetCategoriesQuery();

  const [updateCategory, { isLoading: isUpdating }] = useUpdateCategoryMutation();
  const [createCategory, { isLoading: isCreating }] = useCreateCategoryMutation();

  const [rewardCategoryCatalog, setRewardCategoryCatalog] = useState([]);

  const {
    onResetForm,
    formState: form,
    onSetForm,
    onInputChange: handleInputChange
  } = useForm({
    description: "",
    automatic: false,
    points: 0,
    measureId: 0,
    maxPoints: 0
  });

  const columns = useMemo(() => [
    {
      accessorKey: 'description',
      header: "Category"
    },
    {
      header: "Automatic",
      id: 'auto',
      accessorFn: (row) => (row.automatic ? 'YES' : 'NO'),
      size: 140, //large column

    },
    {
      accessorKey: 'points',
      header: "Points",
      size: 110, //medium column

    },
    {
      accessorKey: 'measureLabel',
      header: "Measure",
      size: 130,
    },
    {
      accessorKey: 'maxPoints',
      header: "Max Points",
      size: 130
    },
  ], []);

  useEffect(() => {
    if (measureData) {
      setMeasureOptions(measureData.map(
        (category) => ({
          value: category.measureId,
          label: category.measure
        })
      ));
    }
  }, [measureData]);

  useEffect(() => {
    if (categoriesList) {
      const categoriesListMap = CategoryMapping(categoriesList);
      setRewardCategoryCatalog(categoriesListMap);
    }
  }, [categoriesList]);

  // Form Validations
  useEffect(() => {
    if (form.description.length === 0) {
      setErrorValidations({
        ...errorValidations,
        description: ''
      });
      return;
    }
    // To show messages
    isValidDescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.description]);

  useEffect(() => {
    if (+form.maxPoints > 0) {
      // To show messages
      isValidPoints();
    } else {
      setErrorValidations((errorState) => ({
        ...errorState,
        points: ''
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.points, form.maxPoints]);

  const isValidPoints = () => {
    if (+form.points > +form.maxPoints) {
      setErrorValidations((errorState) => ({
        ...errorState,
        points: `Points should not exceeded limit ${Number(form.maxPoints).toString()} points`
      }));
      return false;
    } else {
      setErrorValidations((errorState) => ({
        ...errorState,
        points: ''
      }));
      return true;
    }
  }

  const isValidDescription = () => {
    if (!/^[a-zA-Z0-9&$() -]+$/.test(form.description) && form.description.length < 80) {
      setErrorValidations((errorState) => ({
        ...errorState,
        description: 'Description should not contains special characters'
      }));
      return false;
    } else {
      setErrorValidations((errorState) => ({
        ...errorState,
        description: ''
      }));
      return true;
    }
  }

  const mappingFormUpdate = (form) => ({
    categoryId: form.id,
    description: form.description,
    automatic: form.automatic,
    points: form.points,
    measureId: form.measure,
    measure: form.measureLabel,
    maxPoints: form.maxPoints
  });

  const handleSubmit = async () => {
    if (form.description.length > 80) {
      setErrorValidations((errorState) => ({
        ...errorState,
        description: 'The description must be less than 80 characters'
      }));
      toastError("Please fill out the required fields");
      return;
    }

    if (!isValidDescription()) {
      toastError("Please fill out the required fields");
      return;
    }

    if (+form.maxPoints > 0) {
      if (!isValidPoints()) {
        toastError("Please fill out the required fields");
        return;
      } else {
        setErrorValidations((errorState) => ({
          ...errorState,
          points: ''
        }));
      }
    }

    try {
      const { type } = modalSettings;
      if (type === "edit") {
        form.measureId = Number(form.measureId);
        form.measure = form.measureId;
        form.measureLabel = measureOptions.find(
          (measureItem) => measureItem.value === form.measure
        ).label;

        const formData = mappingFormUpdate(form);

        const body = { ...formData, categoryId: form.id }

        await updateCategory({
          categoryId: form.id,
          body
        });
        return;
      }
      await createCategory({ body: form });
    } catch (error) {
      throw new Error(`Error saving form info ${JSON.stringify(form)}`);
    } finally {
      handleCloseModal();
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    onResetForm();
  }

  const handleModal = (type, values) => {
    if (type === 'new') {
      setOpenModal(true);
      setModalSettings({
        type,
        text: 'Add new category'
      });
      const selected = {
        target: { name: 'measureId', value: measureOptions[0].value }
      };
      handleInputChange(selected);
    } else if (type === 'edit') {
      setOpenModal(true);
      setModalSettings({
        type,
        text: 'Edit category'
      });
      values.measureId = values.measure;
      onSetForm(values);
    }
  }

  return ((isUpdating || isLoadingCategories || isLoadingMeasure || isCreating) ? <Loader /> :
    <div className="animate__animated animate__fadeIn">
      <Title>
        <Logout title={'Category Catalog'} />
      </Title>
      <div className="reward-new-category__action">
        <Button block icon={faPlusCircle} onClick={() => { handleModal('new') }}>New Category</Button>
      </div>


      <MaterialReactTable
        title="Category"
        enableColumnActions={false}
        enableColumnFilters={false}
        enableColumnResizing
        enableStickyHeader
        muiTableContainerProps={{
          sx: {
            height: {
              lg: '225px',
              xl: '550px'
            }
          }
        }}
        // enablePagination={false}
        // enableSorting={false}
        // enableBottomToolbar={false}
        enableTopToolbar={false}
        columns={columns}
        data={rewardCategoryCatalog}
        muiTableBodyCellProps={{
          sx: {
            fontSize: '16px',
            fontFamily: 'AvantGardeMedium'
          },
        }}
        muiTableBodyP
        muiTableHeadCellProps={{
          sx: {
            fontWeight: 'bold',
            fontSize: '16px',
            fontFamily: 'AvantGardeMedium'
          },
        }}
        muiTableBodyProps={{
          sx: {
            '& tr:nth-of-type(odd)': {
              backgroundColor: '#f5f5f5',
              padding: '.5rem'
            },
          },
        }}
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
          },
        }}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={({ row }) => (
          <Button id="action-edit" onClick={() => {
            handleModal('edit', row.original)
          }}>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
        )}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: 'Edit',
            size: 100,
          },
        }}
      />

      <Modal open={openModal} onClose={() => handleCloseModal()}>
        <Title>{modalSettings?.text}</Title>
        <section className="reward-category-catalog__form">

          <Form classes="center" onSubmit={handleSubmit}>
            <Label type="grid">
              Description
              <Input
                type="text"
                placeholder="Description"
                name="description"
                value={form.description}
                required
                autoComplete="off"
                onChange={(e) => handleInputChange(e)}
              />
            </Label>
            <div className="message">
              <small className="warning">
                {errorValidations.description}
              </small>
            </div>
            <Label type="grid">
              Automatic
              <div>
                <Checkbox
                  type="checkbox"
                  name="automatic"
                  value={form.automatic}
                  onChange={(e) => {
                    const { target } = e;
                    const checked = {
                      target: { name: 'automatic', value: target.checked }
                    };
                    handleInputChange(checked);
                  }}
                  checked={form.automatic} />
              </div>
            </Label>
            <Label type="grid">
              Points
              <Input
                type="number"
                placeholder="Points"
                name="points"
                value={Number(form.points).toString()}
                min="0"
                required
                autoComplete="off"
                onChange={(e) => handleInputChange(e)}
              />
            </Label>
            <div className="message">
              <small className="warning">
                {errorValidations.points}
              </small>
            </div>
            <Label type="grid">
              Measure
              <Select
                selectedValue={form.measureId}
                onChange={(e) => handleInputChange(e)}
                name="measureId"
                value={form.measureId}
                required
                options={measureOptions} />
            </Label>
            <Label type="grid">Max
              Points
              <Input
                type="number"
                placeholder="Max Points"
                name="maxPoints"
                required
                value={Number(form.maxPoints).toString()}
                min="0"
                autoComplete="off"
                onChange={(e) => handleInputChange(e)}
              />
            </Label>
            <div className="form__actions">
              <Button type="button" disabled={isCreating || isUpdating} secondary onClick={() => handleCloseModal()} >Cancel</Button>
              <Button type="submit" disabled={isCreating || isUpdating} onClick={() => handleSubmit}>Save</Button>
            </div>
          </Form>
        </section>
      </Modal>
    </div>

  )
};

export default RewardCategoryCatalog;