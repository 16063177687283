import { Auth } from '@aws-amplify/auth';
import { setLogout } from "./authSlice";
import { StorageItems } from '../../../utils/constants';

export const startLogout = () => {
  return async (dispatch) => {
    localStorage.removeItem(StorageItems.ACCESS_TOKEN);
    localStorage.removeItem(StorageItems.TIME_INTERACTION);

    await Auth.signOut();
    dispatch(setLogout());
  }
}
