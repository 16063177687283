import { CategoryData } from "./constants";

export const CategoryMapping = (categories) => categories.map(
    (category) => {
        const categoryMappedList = {};
        Object.keys(category).forEach((categoryKey) => {
            categoryMappedList[
                CategoryData[categoryKey]
            ] = category[categoryKey]
        })
        return categoryMappedList;
    });
