import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import userContext from "./config/contextUser";
import Login from "./components/Login";
import Sidebar from "./components/Sidebar";
import Card from "./components/Card";
import MainContainer from "./components/MainContainer";
import appRoutes from "./config/routes";
import { getMenuSidebarByUserRoles } from "./components/SidebarData";
import { SSO_ROLES } from "./utils/constants";
import { SessionTimeOut } from "./views/Manager/components/SessionTimeOut";
import { useSelector } from "react-redux";

const App = () => {

  const [userInfo, setUserInfo] = useState(useContext(userContext).user);
  const [isLoading, setIsLoading] = useState(useContext(userContext).loading);
  const [menuForUserRole, setMenuForUserRole] = useState([]);
  const [appRouting, setAppRouting] = useState([]);
  const { isAuthenticated } = useSelector(state => state.auth);

  const fetchRoutes = async (roles) => {
    const routes = await getMenuSidebarByUserRoles(roles);

    const accessAppRoutes = appRoutes.map((appRoute) => ({
      ...appRoute,
      ...routes[
      routes
        .map((menu) => menu.path)
        .indexOf(appRoute.path)
      ]
    }))
      .filter((route) => route.access !== undefined || route.path === '/');

    setAppRouting(accessAppRoutes);
    setMenuForUserRole(routes);
  }

  useEffect(() => {
    if (userInfo) {
      const Roles = userInfo.roles
        .map((role) => (SSO_ROLES[role]))
        .filter((role) => role !== undefined);
      fetchRoutes(Roles);
    }
  }, [userInfo]);

  return (
    <userContext.Provider value={{
      user: userInfo,
      setUser: setUserInfo,
      loading: isLoading,
      setLoading: setIsLoading,
      routes: menuForUserRole,
      setRoutes: setMenuForUserRole,
    }}
    >
      <SessionTimeOut />
      <Router>
        {isAuthenticated ? (

          <MainContainer>
            <Sidebar />
            <Card isMain>
              <Routes>
                {
                  appRouting.map(({ path, component, roles }, key) => (
                    <Route exact path={path} element={component} key={key} />
                  ))
                }
              </Routes>
            </Card>
          </MainContainer>
        ) :
          <Routes>
            <Route path="/" element={<Login />} />
          </Routes>
        }
      </Router>

    </userContext.Provider>
  )
}


export default App;
