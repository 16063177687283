import { createSlice } from '@reduxjs/toolkit';

export const focalPointSlice = createSlice({
  name: 'focalPoint',
  initialState: {
    employees: [],
    isLoadingEmployees: false
  },
  reducers: {
    startLoadingEmployees: (state) => {
      state.isLoadingEmployees = true;
    },
    setEmployees: (state, action) => {
      const { payload } = action;

      state.employees = payload.employees;
      state.isLoadingEmployees = false;
    },
  }
});

export const { startLoadingEmployees, setEmployees } = focalPointSlice.actions;