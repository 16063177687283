import { useEffect, useState } from "react";
import "./Input.scss";
import { orderBy } from "../utils/utils";

export const InputSelect = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props.options) {
      const order = orderBy(props.options, 'label');
      setOptions(order)
    }
  }, [props.options])


  return (
    <>
      <input list="items"  {...props} />
      <datalist id="items">
        {
          options.map((item) => (
            <option key={item.label}>{item.label}</option>
          ))
        }
      </datalist>
    </>
  )
}
