// import { Navigate } from "react-router-dom";
import Employee from "../views/Employee";
// import Manager from "../views/Manager";
import PointsByEmployee from "../views/Manager/_PointsByEmployee";
import CoePoints from "../views/Manager/_CoePoints";
import CoeSetUp from "../views/Manager/_CoeSetUp";
import RewardCategoryCatalog from "../views/Manager/_RewardCategoryCatalog";
import RedeemPoints from "../views/Manager/_RedeemPoints";
import EmployeesPoints from "../views/Manager/_EmployeesPoints";
// import { UserAdministrator } from "../views/Manager/_UserAdministrator";

// navigate component may change for a new component to redirect by role
const appRoutes = [
    { path: '/', component: <Employee />, roles: ['Admin', 'Manager', 'Rewarder'] },
    { path: '/points-by-employee', component: <PointsByEmployee />, roles: ['Admin', 'Manager'] },
    { path: '/coe-points', component: <CoePoints />, roles: ['Admin', 'Rewarder'] },
    { path: '/coe-setup', component: <CoeSetUp />, roles: ['Admin'] },
    { path: '/reward-category-catalog', component: <RewardCategoryCatalog />, roles: ['Admin'] },
    { path: '/redeem-points', component: <RedeemPoints />, roles: ['Admin'] },
    { path: '/employees-points', component: <EmployeesPoints />, roles: ['Admin'] },
];

export default appRoutes;