import Select from '../../../components/Select';

export const Filters = ({
  selectedQuarter,
  selectedYear,
  selectedWeekEnding,
  handleSelect,
  years,
  quarters,
  weekEndings,
  initialFilter
}) => {

  return (
    <div className="points-by-employee__filters">
      <div className="align__filters">
        <div className="margin-align__filters">
          <div>
            <strong>Year</strong>
          </div>
          <div>
            <Select
              selectedValue={selectedYear}
              name="selectedYear"
              onChange={(e) => handleSelect(e)}
              options={years} />
          </div>
        </div>
        <div className="margin-align__filters">
          <div>
            <strong>Quarter</strong>
          </div>
          <div>
            <Select
              selectedValue={selectedQuarter}
              name="selectedQuarter"
              onChange={(e) => handleSelect(e)}
              options={quarters} />
          </div>
        </div>
        <div className="margin-align__filters">
          <div>
            <strong>Week ending</strong>
          </div>
          <div>
            <Select
              selectedValue={selectedWeekEnding}
              name="selectedWeekEnding"
              onChange={(e) => handleSelect(e)}
              options={weekEndings} />
          </div>
        </div>
        <div className="points-by-employee__filters-dates">
          <div>
            <strong>From:</strong>
            <span>{initialFilter?.from}</span>
          </div>
          <div>
            <strong>To:</strong>
            <span>{initialFilter?.to}</span>
          </div>
        </div>
      </div>

    </div>
  );
}
